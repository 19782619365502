import { useState, useEffect } from "react";
import MUIRichTextEditor from "mui-rte";
import { ContentState, convertFromHTML, convertToRaw } from "draft-js";
import { convertToHTML } from "draft-convert";

const RichEditor = ({
	initialValue = "",
	disabled,
	onChange,
	label,
	placeholder,
}) => {
	const [content, setContent] = useState();

	useEffect(() => {
		const contentHTML = convertFromHTML(initialValue || "");
		const state = ContentState.createFromBlockArray(
			contentHTML.contentBlocks,
			contentHTML.entityMap
		);
		const content = JSON.stringify(convertToRaw(state));
		setContent(content);
	}, [initialValue]);

	return (
		<MUIRichTextEditor
			label={placeholder || label}
			readOnly={disabled}
			controls={[
				"title",
				"bold",
				"italic",
				"underline",
				"numberList",
				"bulletList",
				"quote",
				"code",
			]}
			defaultValue={content}
			onChange={(state) => {
				onChange(convertToHTML(state.getCurrentContent()));
			}}
		/>
	);
};

export default RichEditor;
