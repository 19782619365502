import { useState } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import {
	AppBar,
	Box,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Toolbar,
	Typography,
	Button,
	Container,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

import LanguageSwitch from "../components/buttons/LanguageSwitch";
import { SIDE_WIDTH, FOOTER_HEIGHT } from "../defines";

const navItems = [
	{ href: "home", label: "home" },
	{ href: "project", label: "project" },
	{ href: "organization", label: "organization" },
	{ href: "login", label: "login" },
];

const AppBarLayout = () => {
	const { t } = useTranslation();
	const [mobileOpen, setMobileOpen] = useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	return (
		<>
			<AppBar component="nav">
				<Container>
					<Toolbar disableGutters={true}>
						<Box
							sx={{
								color: "#fff",
								display: "flex",
								flex: 1,
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<IconButton
								color="inherit"
								onClick={handleDrawerToggle}
								sx={{ ml: 0, display: { md: "none" } }}
							>
								<MenuIcon />
							</IconButton>
							<Box
								sx={{
									display: {
										xs: "none",
										md: "flex",
									},
									alignItems: "center",
								}}
							>
								<img alt="logo" src="/icon.png" style={{ height: 40 }} />
								<Typography
									variant="h6"
									component="div"
									sx={{
										fontSize: "small",
										lineHeight: 1.2,
										ml: 1,
										textTransform: "uppercase",
									}}
								>
									<Trans t={t}>common.mlsp</Trans>
								</Typography>
							</Box>
							<Box sx={{ display: { xs: "none", md: "flex" } }}>
								{navItems.map(({ href, label }) => (
									<Button
										component={NavLink}
										key={label}
										to={href}
										style={({ isActive }) => {
											return isActive
												? { borderBottom: "1px solid #fff" }
												: null;
										}}
										sx={{ color: "#fff", borderRadius: 0, mx: 1 }}
									>
										{t(`menu.${label}`)}
									</Button>
								))}
							</Box>
							<LanguageSwitch />
						</Box>
					</Toolbar>
				</Container>
			</AppBar>
			<Box component="nav">
				<Drawer
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					keepMounted
				>
					<Box
						onClick={handleDrawerToggle}
						sx={{ textAlign: "center", width: SIDE_WIDTH }}
					>
						<Typography
							variant="h6"
							sx={{
								my: 2,
								fontSize: "small",
								lineHeight: 1.2,
								maxWidth: 200,
								mx: "auto",
							}}
						>
							{t("common.system_name")}
						</Typography>
						<Divider />
						<List>
							{navItems.map(({ href, label }) => (
								<ListItem key={label} dense={true}>
									<ListItemButton
										sx={{ textAlign: "center" }}
										component={Link}
										to={href}
									>
										<ListItemText primary={t(`menu.${label}`)} />
									</ListItemButton>
								</ListItem>
							))}
						</List>
					</Box>
				</Drawer>
			</Box>
			<Box
				component="main"
				sx={{
					width: 1,
					mb: FOOTER_HEIGHT,
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Toolbar />
				<Outlet />
				<Box
					sx={{
						zIndex: 1,
						position: "fixed",
						bottom: 0,
						width: 1,
						bgcolor: grey[200],
						height: FOOTER_HEIGHT,
					}}
				>
					<Container
						sx={{
							height: 1,
							display: "flex",
							alignItems: "center",
							justifyContent: { xs: "center", md: "space-between" },
						}}
					>
						<Typography
							sx={{ fontSize: "small", display: { xs: "none", md: "block" } }}
						>
							{t("common.footer")}
							<br />
							{t("common.subfooter")}
						</Typography>
						<Box>
							<img
								alt="logo"
								src={t("common.funded")}
								style={{
									maxHeight: 60,
									width: "100%",
									objectFit: "contain",
									marginTop: 20,
								}}
							/>
						</Box>
					</Container>
				</Box>
			</Box>
		</>
	);
};

export default AppBarLayout;
