import { FileDownload } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import List from "../../components/List";
import { mainApi } from "../../providers/api";
import { useAuth } from "../../providers/auth";
import Card from "./Card";
import * as XLSX from "xlsx";
import { format, parseISO } from "date-fns";
import objectPath from "object-path";
import i18next from "i18next";
import { thousandSeparator } from "../../utils";

const columns = [
	{
		label: "Төслийн нэр",
		render: { field: "name" },
	},
	{
		label: "Нийтлэгдсэн эсэх",
		render: ({ published }) => (published ? "Нийтлэгдсэн" : "Драфт"),
	},
	{
		label: "Төлөв",
		render: ({ status }) => i18next.t(`const.status.${status}`),
	},
	{
		label: "Төслийн код",
		render: { field: "code" },
	},
	{
		label: "Чиглэл",
		render: ({ sectors }) =>
			Array.isArray(sectors) && sectors.map(({ name }) => name).join("\n"),
	},
	{
		label: "Зорилтот бүлэг",
		render: ({ segments }) =>
			Array.isArray(segments) && segments.map(({ name }) => name).join("\n"),
	},
	{
		label: "Байршил",
		render: ({ location }) =>
			Array.isArray(location) &&
			location.map((name) => i18next.t(`const.location.${name}`)).join("\n"),
	},
	{
		label: "Үзсэн тоо",
		render: { field: "view_count" },
	},
	{
		label: "Эхлэх огноо",
		render: { field: "start", type: "date" },
	},
	{
		label: "Дуусах огноо",
		render: { field: "end", type: "date" },
	},
	{
		label: "Санхүүжүүлэгч",
		render: ({ funders }) =>
			Array.isArray(funders) && funders.map(({ name }) => name).join("\n"),
	},
	{
		label: "Санхүүжилт",
		render: ({ budget, currency }) => thousandSeparator(budget, currency?.name),
	},
	{
		label: "Зарцуулалт",
		render: ({ budget_percent, currency }) =>
			thousandSeparator(budget_percent, currency?.name),
	},
	{
		label: "Эх үүсвэр",
		render: ({ source }) => i18next.t(`const.source.${source}`),
	},
	{
		label: "Үндсэн хэрэгжүүлэгч",
		render: ({ implementers }) =>
			Array.isArray(implementers) &&
			implementers.map(({ name }) => name).join("\n"),
	},
	{
		label: "Төслийн зорилго",
		render: { field: "purpose" },
	},
	{
		label: "Хүлээгдэж буй үр дүн",
		render: { field: "expected" },
	},
	{
		label: "Хэрэгжилтийн байдал",
		render: { field: "result" },
	},
	{
		label: "Явц хувиар",
		render: { field: "progress" },
	},
	{
		label: "Үндсэн хэрэгжүүлэгч",
		render: ({ implementers }) =>
			Array.isArray(implementers) &&
			implementers.map(({ name }) => name).join("\n"),
	},
	{
		label: "Хариуцах мэргэжилтэн",
		render: ({ users }) =>
			Array.isArray(users) &&
			users
				.map(
					({ position, name, email, phone }) =>
						`${position} ${name} (${email} ${phone})`
				)
				.join("\n"),
	},
	{
		label: "Сүүлд мэдээлэл оруулсан огноо",
		render: {
			field: "modified",
			format: "yyyy-MM-dd HH:mm:ss",
		},
	},
];

const ProjectList = () => {
	const { user } = useAuth();
	const { t } = useTranslation();

	const onClickDownload = async () => {
		const response = await mainApi({
			url: "/project/table",
			method: "POST",
			data: {
				sort: { created: 1 },
				limit: 300,
			},
		});
		await exportToExcel(response.data.items);
	};

	const adaptRender = (row, render) => {
		if (typeof render === "function") {
			return render(row);
		}
		if (typeof render === "object") {
			const { type, field } = render;
			const value = objectPath.get(row, field);

			if (type === "date" && value) {
				return format(parseISO(value), render.format || "yyyy-MM-dd");
			}

			return value;
		}
	};

	const exportToExcel = (rows) => {
		const header = columns.map((column) => {
			return column.label;
		});

		const data = rows.map((row) => {
			return columns.map(({ render }) => adaptRender(row, render));
		});

		let wb = XLSX.utils.book_new();
		let ws = XLSX.utils.json_to_sheet([header, ...data], {
			skipHeader: true,
		});

		ws["!cols"] = columns.map(({ width }) => ({
			wch: width || 25,
		}));

		XLSX.utils.book_append_sheet(wb, ws);

		return XLSX.writeFile(wb, `Төслийн тайлан.xlsx`);
	};

	return (
		<Box sx={{ p: 4 }}>
			<List
				add={user?.role === "superior"}
				sort={{ created: -1 }}
				more
				url="/project/table"
				columns={[
					{
						field: "name",
						filter: true,
					},
					{
						field: "trans.us.name",
						filter: true,
					},
				]}
				renderList={({ items }) => (
					<Grid container spacing={2} sx={{ my: 2 }}>
						{items.map((item) => (
							<Card key={item._id} root="/app/project/view/" {...item} />
						))}
					</Grid>
				)}
				renderHeader={() => {
					return (
						<Button
							variant="outlined"
							endIcon={<FileDownload />}
							sx={{ mr: 1 }}
							onClick={() => {
								onClickDownload();
							}}
						>
							<Typography sx={{ display: { xs: "none", md: "block" } }}>
								{t("button.export")}
							</Typography>
						</Button>
					);
				}}
			/>
		</Box>
	);
};

export default ProjectList;
