import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Toolbar } from "@mui/material";
import { Cancel, Check, Edit } from "@mui/icons-material";
import Back from "../buttons/Back";

const ToolBar = ({ readonly, disabled, setDisabled, loading }) => {
	const { t } = useTranslation();

	return (
		<Toolbar disableGutters={true}>
			<Box
				sx={{
					width: 1,
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Back />
				{!readonly && (
					<Box>
						{disabled ? (
							<Button
								variant="outlined"
								disabled={loading}
								onClick={() => {
									setDisabled(!disabled);
								}}
								startIcon={disabled ? <Edit /> : <Cancel />}
							>
								{t(disabled ? "button.edit" : "button.cancel")}
							</Button>
						) : (
							<LoadingButton
								color="primary"
								variant="contained"
								type="submit"
								disabled={disabled}
								loading={loading}
								startIcon={<Check />}
								sx={{ ml: 1 }}
							>
								{t("button.save")}
							</LoadingButton>
						)}
					</Box>
				)}
			</Box>
		</Toolbar>
	);
};

export default ToolBar;
