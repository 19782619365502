import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { mainApi } from "../../providers/api";

import { ResponsiveContainer, Treemap } from "recharts";
import { Backdrop, Box, CircularProgress, Grid } from "@mui/material";
import { Link } from "react-router-dom";

const UnitCell = (props) => {
	const { depth, x, y, width, height, color, name, total } = props;
	const isUnit = depth === 1;
	const display = typeof name === "string" ? name.toUpperCase() : name;

	return (
		<g>
			{total > 0 ? (
				<rect
					x={x}
					y={y + 22}
					width={width}
					height={height}
					fill={color}
				></rect>
			) : (
				<rect
					x={x}
					y={y}
					width={width}
					height={height}
					style={{
						fill: "#14213d",
						stroke: isUnit && "#fff",
					}}
				/>
			)}
			<text x={x + 4} y={y + 16 + (isUnit ? 0 : 22)} fill="#fff" fontSize={12}>
				{isUnit ? "" : `(${total})`} {display}
			</text>
		</g>
	);
};

const TMChart = ({ type = "funders", color }) => {
	const { t, i18n } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();
	const [orgs, setOrgs] = useState();

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const response = await mainApi(`/public/org/${type}`);

			setOrgs(response.data);
			setData([
				{
					name: t("label.project_count"),
					children: response.data.map(({ short_mon, short_eng, total }) => ({
						name: i18n.language === "mon" ? short_mon : short_eng,
						total,
					})),
				},
			]);
			setLoading(false);
		};

		fetchData();
	}, [type, i18n.language, t]);

	return (
		<Box>
			<Backdrop sx={{ color: "#fff", zIndex: 11 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Box sx={{ height: 450 }}>
				<ResponsiveContainer>
					<Treemap
						height={450}
						width={"100%"}
						data={data}
						dataKey="total"
						ratio={3 / 4}
						stroke="#fff"
						fill={color || "#fca712"}
						content={<UnitCell />}
					/>
				</ResponsiveContainer>
			</Box>
			<Grid container sx={{ display: "flex", mt: 4 }}>
				{Array.isArray(orgs) &&
					orgs.map((org) => {
						return (
							<Grid
								item
								key={org._id}
								fontSize="small"
								sm={6}
								md={4}
								xs={12}
								component={Link}
								to={`/page/organization/?org_id=${org._id}`}
								sx={{ color: "primary.main" }}
							>
								{i18n.language === "mon" ? org.name_mon : org.name_eng} (
								{org.total})
							</Grid>
						);
					})}
			</Grid>
		</Box>
	);
};

export default TMChart;
