import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import Field from "../../components/form/Field";
import Form from "../../components/form/Form";
import Group from "../../components/Group";
import { useAuth } from "../../providers/auth";

const OrgForm = () => {
	const { id } = useParams();
	const { user } = useAuth();

	const form = {
		url: "org",
		id,
		readonly: user.role !== "superior",
		initial: {
			name: "",
			type: "",
			short: "",
			trans: {
				us: {
					name: "",
					short: "",
				},
			},
			contact: {
				worker: "",
				position: "",
				email: "",
				phone: "",
			},
			logo: [],
		},
		validation: Yup.object({
			logo: Yup.array().of(Yup.object()).min(1, "Лого оруулна уу"),
			type: Yup.string().required("Төрөл сонгоно уу"),
			name: Yup.string().required("Нэр оруулна уу"),
			short: Yup.string().required("Товчилсон нэр оруулна уу"),
			trans: Yup.object({
				us: Yup.object({
					name: Yup.string().required("Англиар нэр оруулна уу"),
					short: Yup.string().required("Англиар товчилсон нэр оруулна уу"),
				}),
			}),
		}),
		sx: { width: 1 },
		onSubmitted: id ? () => {} : null,
	};

	return (
		<Box>
			<Form {...form}>
				{({ disabled, submitted }) => {
					return (
						<Box sx={{ display: { xs: "grid", lg: "flex" }, gap: 2 }}>
							<Group sx={{ flex: 2 }} title="Үндсэн мэдээлэл">
								<Box sx={{ display: { xs: "grid", lg: "flex" }, gap: 2 }}>
									<Field
										sx={{ flex: 1 }}
										disabled={disabled}
										name="logo"
										label="Лого"
										as="upload"
										root="org"
										submitted={submitted}
									/>
									<Box
										sx={{
											flex: 1,
											display: "grid",
											gap: 2,
											alignContent: "start",
										}}
									>
										<Field
											disabled={disabled}
											name="type"
											label="Төрөл"
											as="buttons"
											path="org_type"
										/>
										<Field
											disabled={disabled}
											sx={{ width: "100%" }}
											name="name"
											label="Байгууллагын нэр"
										/>
										<Field
											disabled={disabled}
											sx={{ width: "100%" }}
											name="trans.us.name"
											label="Байгууллагын нэр /English/"
										/>
										<Field
											disabled={disabled}
											sx={{ width: "100%" }}
											name="short"
											label="Товчилсон нэр"
										/>
										<Field
											disabled={disabled}
											sx={{ width: "100%" }}
											name="trans.us.short"
											label="Товчилсон нэр /English/"
										/>
									</Box>
								</Box>
							</Group>
							<Group title="Холбогдох ажилтны мэдээлэл">
								<Field disabled={disabled} name="contact.worker" label="Нэр" />
								<Field
									disabled={disabled}
									name="contact.position"
									label="Албан тушаал"
								/>
								<Field disabled={disabled} name="contact.email" label="Имэйл" />
								<Field disabled={disabled} name="contact.phone" label="Утас" />
							</Group>
						</Box>
					);
				}}
			</Form>
		</Box>
	);
};

export default OrgForm;
