import { Add, ArrowDownward, ArrowUpward, Delete } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { grey } from "@mui/material/colors";
import { FieldArray } from "formik";

const FormFieldArray = ({
	name,
	items = [],
	children,
	init = {},
	disabled,
}) => {
	return (
		<FieldArray
			name={name}
			render={({ remove, insert, push, swap }) => (
				<>
					{items &&
						items.map((item, index) => (
							<Box key={index}>
								<Box sx={{ background: grey[50] }}>
									<IconButton
										disabled={disabled}
										color="primary"
										onClick={() => insert(index, init)}
									>
										<Add />
									</IconButton>
									<IconButton
										disabled={disabled}
										color="primary"
										onClick={() => remove(index)}
									>
										<Delete />
									</IconButton>
									{index !== 0 && (
										<IconButton
											disabled={disabled}
											color="primary"
											onClick={() => swap(index, index - 1)}
										>
											<ArrowUpward />
										</IconButton>
									)}
									{index + 1 < items.length && (
										<IconButton
											disabled={disabled}
											color="primary"
											onClick={() => swap(index, index + 1)}
										>
											<ArrowDownward />
										</IconButton>
									)}
								</Box>
								{children && children(item, index)}
							</Box>
						))}
					<Box sx={{ background: grey[50] }}>
						<IconButton
							disabled={disabled}
							onClick={() => push(init)}
							color="primary"
						>
							<Add />
						</IconButton>
					</Box>
				</>
			)}
		/>
	);
};

export default FormFieldArray;
