import {
	ComposableMap,
	Geographies,
	Geography,
	Marker,
} from "react-simple-maps";
import { geoCentroid } from "d3-geo";
import topo from "./mongolia_topo.json";

const Map = ({ label, className, actives, selected, setSelected }) => {
	return (
		<div className={className}>
			<ComposableMap
				projectionConfig={{
					scale: 1410,
					center: [103.82, 47.1],
				}}
				projection="geoMercator"
				height={400}
			>
				<Geographies geography={topo}>
					{({ geographies }) => (
						<>
							{geographies.map((geo) => {
								const { zipcode } = geo.properties;
								const active = selected == zipcode;

								const alpha = label && label(zipcode) / 10 + 0.2;

								return (
									<Geography
										onClick={() => {
											if (setSelected) {
												setSelected(zipcode);
											}
										}}
										key={geo.rsmKey}
										geography={geo}
										style={{
											default: {
												fill: active
													? "#14213d"
													: alpha
													? `rgba(129,173,210,${alpha})`
													: "#81add2",
												outline: "none",
												cursor: "pointer",
											},
											hover: actives
												? {
														fill: "#81add2",
														outline: "none",
												  }
												: {
														fill: "#14213d",
														outline: "none",
												  },
											pressed: {
												outline: "none",
											},
										}}
									></Geography>
								);
							})}
							{label &&
								geographies.map((geo) => {
									const { zipcode } = geo.properties;
									const centroid = geoCentroid(geo);

									return (
										<Marker coordinates={centroid} key={zipcode}>
											<text
												y="2"
												fontSize={10}
												textAnchor="middle"
												fill="#ffffff"
											>
												{label ? label(zipcode) : zipcode}
											</text>
										</Marker>
									);
								})}
						</>
					)}
				</Geographies>
			</ComposableMap>
		</div>
	);
};

export default Map;
