import { Button, ButtonGroup, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { mainApi } from "../../providers/api";
import * as CONSTS from "../../consts";
import { useTranslation } from "react-i18next";

const Buttons = ({
	axio,
	axioAdapter,
	options = [],
	keyField = "_id",
	displayField = "name",
	path,
	lang = "mn",

	value,
	disabled,
	onChange,
	onChanged,
	multi,

	...props
}) => {
	const { t } = useTranslation();
	const [items, setItems] = useState(options);
	const [loading, setLoading] = useState(false);
	const [selected, setSelected] = useState(multi ? [] : null);

	const handleChange = (key) => {
		let _selected = selected;
		if (multi) {
			if (_selected.includes(key)) {
				_selected.splice(_selected.indexOf(key), 1);
			} else {
				_selected.push(key);
			}
		} else {
			if (_selected === key) {
				_selected = null;
			} else {
				_selected = key;
			}
		}
		onChange(_selected);
	};

	useEffect(() => {
		let selected = multi
			? items.filter((item) => value?.includes(item[keyField]))
			: items.find((item) => value === (item && item[keyField])) || null;

		setSelected(value);
		onChanged && onChanged(selected);
		// eslint-disable-next-line
	}, [items, value]);

	useEffect(() => {
		if (Array.isArray(CONSTS[path]))
			setItems(
				CONSTS[path].map((item) => ({
					_id: item,
					name: t(`const.${path}.${item}`, { lng: lang }),
				}))
			);
		// eslint-disable-next-line
	}, [path, lang]);

	useEffect(() => {
		const fetchItems = async () => {
			setLoading(true);

			const response = await mainApi(axio);
			const data =
				response && (axioAdapter ? axioAdapter(response.data) : response.data);

			setItems(data);
			setLoading(false);
		};

		if (axio) fetchItems();
		// eslint-disable-next-line
	}, [axio]);

	if (loading)
		return (
			<Skeleton variant="rectangular" animation="wave" sx={{ height: 36.5 }} />
		);

	return (
		<ButtonGroup {...props} disabled={disabled}>
			{items.map((item) => {
				const key = item[keyField];
				const display = item[displayField];
				const isActive = multi ? selected?.includes(key) : selected === key;

				return (
					<Button
						{...item}
						key={key}
						type="button"
						onClick={() => {
							handleChange(key);
						}}
						variant={isActive ? "contained" : "outlined"}
					>
						{display}
					</Button>
				);
			})}
		</ButtonGroup>
	);
};

export default Buttons;
