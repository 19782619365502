import packageJson from "../package.json";

export const DEBUG = process.env.NODE_ENV === "development";
export const API_ROOT = DEBUG
	? "http://localhost:3300"
	: "https://projects.mlsp.gov.mn/api";
export const VERSION = packageJson.version;
export const NAME = "SYSTEM_NAME";
export const SIDE_WIDTH = "270px";
export const FOOTER_HEIGHT = "100px";
export const LANGUAGES = ["mon", "eng"];
export const DOMAIN = DEBUG
	? "https://projects.sys.mn"
	: "https://projects.mlsp.gov.mn";
export const GOOGLE_MAP_KEY = "AIzaSyAc3kYQN80ZGKS1erezPH-nsVSuQsX8MeY";
export const GOOGLE_MAP_CENTER = {
	lat: 47.917837389284216,
	lng: 106.91883066563094,
};
