import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import sha256 from "crypto-js/sha256";
import { LoadingButton } from "@mui/lab";
import { Box, Container, Divider, Typography } from "@mui/material";
import { useAuth } from "../providers/auth";
import { DEBUG } from "../defines";
import { firstChar } from "../utils";
import Form from "../components/form/Form";
import Field from "../components/form/Field";
import { mainApi } from "../providers/api";
import { useModal } from "../providers/modal";

const Login = ({ from }) => {
	const { t } = useTranslation();
	const { login, logout } = useAuth();
	const { toast } = useModal();

	useEffect(() => {
		logout();
		if (from === "inside") {
			localStorage.setItem("logged-out", Date.now());
		}
	}, [from, logout]);

	const form = {
		toolbar: null,
		url: "user/login",
		readonly: false,
		initial: {
			email: DEBUG ? "otgonbat.enkhee@gmail.com" : "",
			password: DEBUG ? "asdasd" : "",
		},
		validation: Yup.object({
			email: Yup.string().required(
				firstChar(t("validation.required", { field: t("field.email") }))
			),
			password: Yup.string()
				.min(
					6,
					firstChar(
						t("validation.min", { field: t("field.password"), length: 6 })
					)
				)
				.required(
					firstChar(t("validation.required", { field: t("field.password") }))
				),
		}),
		onSubmit: async ({ email, password }, { setSubmitting }) => {
			try {
				const data = {
					email,
					password: sha256(password).toString(),
				};
				const response = await mainApi({
					url: "/user/login",
					method: "POST",
					data,
				});

				setSubmitting(false);
				login(response.data);
			} catch (error) {
				toast(error, "error");
			}
		},
		sx: { maxWidth: 400, mx: "auto", my: 10 },
	};

	return (
		<Container>
			<Form {...form}>
				{({ disabled }) => (
					<Box sx={{ display: "grid", gap: 2 }}>
						<Typography
							sx={{
								fontSize: "large",
								textAlign: "center",
							}}
						>
							{t("title.login")}
						</Typography>
						<Divider component="div" sx={{ my: 2 }}></Divider>
						<Field
							name="email"
							disabled={disabled}
							label={firstChar(t("field.email"))}
						/>
						<Field
							name="password"
							disabled={disabled}
							label={firstChar(t("field.password"))}
							type="password"
						/>
						<LoadingButton
							color="primary"
							variant="contained"
							fullWidth
							type="submit"
							loading={disabled}
						>
							{t("button.login")}
						</LoadingButton>
					</Box>
				)}
			</Form>
		</Container>
	);
};

export default Login;
