import { useState } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import {
	Backdrop,
	CircularProgress,
	AppBar,
	Box,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Toolbar,
	Typography,
	Menu,
	Avatar,
	Tooltip,
	MenuItem,
	Badge,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

import { useAuth } from "../providers/auth";
import { SIDE_WIDTH, VERSION } from "../defines";
import MENU from "../menu";
import LanguageSwitch from "../components/buttons/LanguageSwitch";

const FOOTER_HEIGHT = "70px";
const SETTINGS = [
	{
		label: "profile",
		to: "/app/profile",
	},
	{
		label: "guide",
		to: "/app/guide",
	},
	{
		label: "notification",
		to: "/app/user-notifications",
	},
	{
		label: "logout",
		to: "/page/logout",
	},
];

const stringAvatar = (name) => {
	return {
		children:
			name.split(" ").length > 1
				? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
				: `${name.split(" ")[0][0]}`,
	};
};

const SideBarLayout = () => {
	const { user, badge } = useAuth();
	const { t } = useTranslation();

	const [mobileOpen, setMobileOpen] = useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const [anchorElUser, setAnchorElUser] = useState(null);

	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	if (!user)
		return (
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={true}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		);

	const { role, firstname } = user;

	const drawer = (
		<>
			<Toolbar>
				<img alt="logo" src="/icon.png" style={{ height: 40 }} />
				<Typography
					variant="h6"
					component="div"
					sx={{
						fontSize: 11,
						lineHeight: 1.2,
						ml: 1,
						textTransform: "uppercase",
					}}
				>
					<Trans t={t}>common.mlsp</Trans>
				</Typography>
			</Toolbar>
			<Divider />
			<List>
				{MENU[role] &&
					MENU[role].map(({ label, to, icon }) => (
						<ListItem key={label} disablePadding dense={true}>
							<ListItemButton
								component={NavLink}
								to={to}
								sx={{
									"&.active": {
										background: "#2a57a4",
										color: "#fff",
									},
									"&.active .MuiListItemIcon-root": {
										color: "#fff",
									},
								}}
							>
								<ListItemIcon>{icon}</ListItemIcon>
								<ListItemText primary={t(`menu.${label}`)} />
							</ListItemButton>
						</ListItem>
					))}
			</List>
		</>
	);

	return (
		<Box sx={{ display: "flex", width: 1 }}>
			<AppBar
				position="fixed"
				sx={{
					width: { md: `calc(100% - ${SIDE_WIDTH})` },
					ml: { md: `${SIDE_WIDTH}` },
				}}
			>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						sx={{ mr: 2, display: { md: "none" } }}
					>
						<MenuIcon />
					</IconButton>
					<Box
						sx={{
							display: "flex",
							flex: 1,
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<Typography
							sx={{
								fontSize: 12,
								lineHeight: 1.2,
							}}
						>
							{t("common.system_name")} v{VERSION}
						</Typography>

						<Box sx={{ flexGrow: 0, display: "flex", alignItems: "center" }}>
							<LanguageSwitch />
							<Box sx={{ ml: 2 }}>
								<Badge badgeContent={badge} color="error">
									<Tooltip title="Хэрэглэгчийн цэс">
										<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
											<Avatar
												{...stringAvatar(firstname)}
												sx={{
													textTransform: "uppercase",
												}}
											/>
										</IconButton>
									</Tooltip>
									<Menu
										sx={{ mt: "45px" }}
										anchorEl={anchorElUser}
										anchorOrigin={{
											vertical: "top",
											horizontal: "right",
										}}
										keepMounted
										transformOrigin={{
											vertical: "top",
											horizontal: "right",
										}}
										open={Boolean(anchorElUser)}
										onClose={handleCloseUserMenu}
									>
										{SETTINGS.map(({ label, to }) => (
											<MenuItem
												component={Link}
												to={to}
												key={label}
												onClick={handleCloseUserMenu}
											>
												<Typography textAlign="center">
													{t(`menu.${label}`)}
													{label === "notification" && badge > 0
														? ` (${badge})`
														: ""}
												</Typography>
											</MenuItem>
										))}
									</Menu>
								</Badge>
							</Box>
						</Box>
					</Box>
				</Toolbar>
			</AppBar>
			<Box
				component="nav"
				sx={{ width: { md: SIDE_WIDTH }, flexShrink: { md: 0 } }}
			>
				<Drawer
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					onClick={handleDrawerToggle}
					sx={{
						display: { xs: "block", md: "none" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: SIDE_WIDTH,
						},
					}}
				>
					{drawer}
				</Drawer>
				<Drawer
					variant="permanent"
					sx={{
						display: { xs: "none", md: "block" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: SIDE_WIDTH,
						},
					}}
					open
				>
					{drawer}
				</Drawer>
			</Box>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					width: { md: `calc(100% - ${SIDE_WIDTH})` },
					mb: FOOTER_HEIGHT,
				}}
			>
				<Toolbar />
				<Box sx={{ p: 2 }}>
					<Outlet />
				</Box>
				<Box
					sx={{
						zIndex: 1,
						position: "fixed",
						bottom: 0,
						right: 0,
						left: { xs: 0, md: SIDE_WIDTH },
						p: 2,
						display: "flex",
						alignItems: "center",
						justifyContent: { xs: "center", lg: "space-between" },
						bgcolor: grey[200],
						height: FOOTER_HEIGHT,
					}}
				>
					<Typography
						sx={{ fontSize: "small", display: { xs: "none", lg: "block" } }}
					>
						{t("common.footer")}
						<br />
						{t("common.subfooter")}
					</Typography>
					<Box>
						<img
							alt="logo"
							src={t("common.funded")}
							style={{
								maxHeight: 50,
								width: "100%",
								objectFit: "contain",
								marginTop: 10,
							}}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default SideBarLayout;
