import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./providers/mui";
import { Box } from "@mui/material";

import { I18nextProvider } from "react-i18next";
import i18n from "./providers/i18n";

import ModalProvider from "./providers/modal";
import AuthProvider from "./providers/auth";
import AppBar from "./layouts/AppBar";
import SideBar from "./layouts/SideBar";

import Home from "./pages/Home";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import PublicProjectList from "./pages/ProjectList";
import PublicProjectDetail from "./pages/ProjectDetail";
import Organization from "./pages/Organization";
import Guide from "./pages/Guide";
import Map from "./pages/Map";

import CategoryList from "./models/category/List";
import CategoryForm from "./models/category/Form";

import OrgList from "./models/org/List";
import OrgForm from "./models/org/Form";

import ProjectList from "./models/project/List";
import ProjectForm from "./models/project/Form";

import UserList from "./models/user/List";
import UserForm from "./models/user/Form";

import NotificationList from "./models/notification/List";
import NotificationForm from "./models/notification/Form";

ReactDOM.createRoot(document.getElementById("root")).render(
	<React.StrictMode>
		<BrowserRouter>
			<I18nextProvider i18n={i18n}>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<Box minHeight="100vh" width="100%" display="flex">
						<ModalProvider>
							<AuthProvider>
								<Routes>
									<Route path="/" element={<Navigate to="page/home" />} />
									<Route path="map" element={<Map />} />
									<Route path="page" element={<AppBar />}>
										<Route path="home" element={<Home />} />
										<Route path="project" element={<PublicProjectList />} />
										<Route
											path="project/:id"
											element={<PublicProjectDetail />}
										/>
										<Route path="organization" element={<Organization />} />
										<Route path="login" element={<Login />} />
										<Route path="logout" element={<Login from="inside" />} />
									</Route>
									<Route path="app" element={<SideBar />}>
										<Route path="category" element={<CategoryList />} />
										<Route
											path="category/view/:id"
											element={<CategoryForm />}
										/>
										<Route path="category/new" element={<CategoryForm />} />

										<Route path="organization" element={<OrgList />} />
										<Route path="organization/view/:id" element={<OrgForm />} />
										<Route path="organization/new" element={<OrgForm />} />

										<Route path="notification" element={<NotificationList />} />
										<Route
											path="notification/new"
											element={<NotificationForm />}
										/>

										<Route path="project" element={<ProjectList />} />
										<Route path="project/view/:id" element={<ProjectForm />} />
										<Route path="project/new" element={<ProjectForm />} />

										<Route path="user" element={<UserList />} />
										<Route path="user/view/:id" element={<UserForm />} />
										<Route path="user/new" element={<UserForm />} />
										<Route path="profile" element={<UserForm profile />} />
										<Route path="guide" element={<Guide />} />
										<Route
											path="user-notifications"
											element={<NotificationList type="user" />}
										/>
									</Route>
									<Route path="*" element={<NotFound />} />
								</Routes>
							</AuthProvider>
						</ModalProvider>
					</Box>
				</ThemeProvider>
			</I18nextProvider>
		</BrowserRouter>
	</React.StrictMode>
);
