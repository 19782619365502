import { Box } from "@mui/material";
import List from "../../components/List";
import { useAuth } from "../../providers/auth";
import { imageField } from "../../utils";

const OrgList = () => {
	const { user } = useAuth();

	return (
		<Box sx={{ p: 4 }}>
			<List
				add={user?.role === "superior"}
				sort={{ created: -1 }}
				more
				url="/org/table"
				columns={[
					{
						field: "logo",
						label: "Лого",
						render: (row) =>
							imageField(row, "logo.0.id", { width: 60, border: "none" }),
					},
					{
						field: "short",
						label: "Товч нэр",
						filter: true,
					},
					{
						field: "name",
						label: "Нэр",
						filter: true,
					},
					{
						field: "trans.us.name",
						label: "Нэр /English/",
						filter: true,
					},
				]}
			/>
		</Box>
	);
};

export default OrgList;
