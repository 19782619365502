import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ArrowBack as Icon } from "@mui/icons-material";

const Back = (props) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<Button
			{...props}
			startIcon={<Icon />}
			onClick={() => {
				navigate(-1);
			}}
		>
			{t("button.back")}
		</Button>
	);
};

export default Back;
