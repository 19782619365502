import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";

const CheckBox = ({
	sx,
	value = false,
	disabled,
	onChange,
	label,
	...props
}) => {
	return (
		<FormGroup>
			<FormControlLabel
				sx={sx}
				disabled={disabled}
				control={
					<Checkbox
						{...props}
						checked={value}
						disabled={disabled}
						onChange={({ target }) => onChange(target.checked)}
					/>
				}
				label={label}
			/>
		</FormGroup>
	);
};

export default CheckBox;
