import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { Box } from "@mui/material";
import FormToolBar from "./ToolBar";
import { mainApi } from "../../providers/api";
import { useModal } from "../../providers/modal";

const Form = ({
	url,
	id,
	view,
	validation,
	initial = {},

	onSubmit,
	onSubmitted,
	onErrored,

	readonly = true,
	toolbar = "top",
	sx,

	children,
}) => {
	const { toast } = useModal();
	const navigate = useNavigate();

	const [disabled, setDisabled] = useState(!!id || readonly);
	const [fetching, setFetching] = useState(false);
	const [data, setData] = useState(initial);
	const [submitted, setSubmitted] = useState();

	useEffect(() => {
		const fetch = async () => {
			try {
				setFetching(true);
				const resposne = await mainApi(
					disabled ? `/${url}/view/${id}` : `/${url}/${id}`
				);

				setData(resposne.data);
			} catch (error) {
			} finally {
				setFetching(false);
			}
		};

		if (id) fetch();
	}, [disabled, url, id]);

	const formikProps = {
		enableReinitialize: true,
		initialValues: data,
		validationSchema: validation,
		onSubmit: onSubmit
			? onSubmit
			: async (values, { setSubmitting }) => {
					try {
						setSubmitted(Date.now());
						const response = await mainApi({
							url: `/${url}${id ? `/${id}` : ""}`,
							method: id ? "PUT" : "POST",
							data: values,
						});
						setDisabled(true);
						setSubmitting(false);

						if (onSubmitted) return onSubmitted(response.data);

						toast("Үйлдэл амжилттай боллоо", "success");
						navigate(-1);
					} catch (error) {
						if (onErrored) return onErrored(error);

						toast(error, "error");
					}
			  },
	};

	return (
		<Formik {...formikProps}>
			{(formik) => {
				const { isSubmitting, handleSubmit } = formik;

				return (
					<Box component="form" sx={sx} onSubmit={handleSubmit}>
						{toolbar === "top" && (
							<FormToolBar
								readonly={
									typeof readonly === "function" ? readonly(data) : readonly
								}
								disabled={disabled}
								setDisabled={setDisabled}
								loading={isSubmitting || fetching}
							/>
						)}

						{disabled && view
							? view(data, fetching)
							: children &&
							  children({
									...formik,
									disabled: disabled || fetching || isSubmitting,
									submitted,
							  })}

						{toolbar === "bottom" && (
							<FormToolBar
								readonly={
									typeof readonly === "function" ? readonly(data) : readonly
								}
								disabled={disabled}
								setDisabled={setDisabled}
								loading={isSubmitting || fetching}
							/>
						)}
					</Box>
				);
			}}
		</Formik>
	);
};

export default Form;
