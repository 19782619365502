import { Box, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Target from "../components/charts/Target";

const Home = () => {
	const { t } = useTranslation();

	return (
		<Container>
			<Box sx={{ mb: 4 }}>
				<Typography
					color="primary.main"
					fontWeight={"bold"}
					textAlign="center"
					fontSize={20}
					my={8}
				>
					{t("label.project_locations")}
				</Typography>
				<Target />
			</Box>
		</Container>
	);
};

export default Home;
