import { useState } from "react";
import { addMonths, format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { mainApi } from "../../providers/api";
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const FORMAT = "yyyy-MM";

const Progress = ({
	index,
	data,
	left = 0,
	max = 0,
	progress = 0,
	language = "mon",
}) => {
	if (!max) return null;
	return (
		<Box
			key={index}
			sx={{
				left,
				width: max,
				position: "absolute",
				top: 0,
				height: 20,
				opacity: "0.5",
				bgcolor: "grey.light",
			}}
		>
			<Box
				sx={{
					position: "absolute",
					height: 20,
					top: 0,
					left: 0,
					bgcolor: "secondary.main",
					width: progress + "%",
				}}
			></Box>
			<Box
				sx={{
					position: "absolute",
					left: 0,
					bottom: 0,
					top: 0,
					width: "100%",
					alignItems: "center",
					justifyContent: "center",
					color: "#333",
				}}
				fontSize="small"
				component={Link}
				to={`/page/project/${data.id}`}
			>
				{data.name[language]} {progress}%
			</Box>
		</Box>
	);
};
const HEIGHT = 50;
const WIDTH = 70;

const GanttChart = ({ length = 12, ignoreStep = 3, background }) => {
	const { t, i18n } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [current, setCurrent] = useState();
	const [months, setMonths] = useState([]);
	const [list, setList] = useState([]);

	useEffect(() => {
		const today = new Date();
		const current = format(today, FORMAT);
		let list = [current];

		for (let i = 1; i <= length; i++) {
			list.unshift(format(addMonths(today, i * -1), FORMAT));
			list.push(format(addMonths(today, i), FORMAT));
		}

		setCurrent(current);
		setMonths(list);
	}, [length]);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const response = await mainApi("/public/gantt");
			setList(response.data);
			setLoading(false);
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const progressCalculator = ({ start, end, progress }) => {
		if (start > end) return null;
		if (start < months[0] && end < months[0]) return null;
		if (start > months[months.length - 1] && end > months[months.length - 1])
			return null;

		const startIndex = months.indexOf(start);
		const endIndex = months.indexOf(end);

		const started = startIndex < 0 ? 0 : startIndex;
		const ended = endIndex < 0 ? months.length : endIndex + 1;

		const duration = ended - started;

		return {
			left: WIDTH * started,
			max: WIDTH * duration,
			progress,
		};
	};

	return (
		<Box>
			<Backdrop sx={{ color: "#fff", zIndex: 11 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Typography
				color="primary.main"
				fontWeight={"bold"}
				textAlign="center"
				fontSize={20}
				my={8}
			>
				{t("label.project_sectors")}
			</Typography>
			{list.map((item) => {
				return (
					<Box
						key={item._id}
						sx={{
							mb: 5,
						}}
					>
						{i18n.language === "mon" ? item.name_mon : item.name_eng}
						<Box sx={{ overflowX: "scroll", overflowY: "hidden" }}>
							<Box sx={{ display: "inline-flex" }} fontSize="small">
								{months &&
									months.map((month, index) => {
										return (
											<Box
												key={month}
												sx={{
													textAlign: "center",
													height: HEIGHT,
													width: WIDTH,
													...(current === month
														? {
																borderLeft: "1px solid black",
																bgcolor: "grey.light",
														  }
														: { borderLeft: "1px solid #eee" }),
												}}
											>
												{index % ignoreStep !== 0 ? "" : month}
											</Box>
										);
									})}
							</Box>
							{item &&
								item.projects.map((project) => (
									<Box
										key={project.id}
										sx={{ display: "inline-flex", position: "relative" }}
									>
										{months &&
											months.map((month, index) => {
												return (
													<Box
														key={month}
														sx={{
															textAlign: "center",
															height: HEIGHT,
															width: WIDTH,
															...(current === month
																? {
																		borderLeft: "1px solid black",
																		bgcolor: "grey.light",
																  }
																: { borderLeft: "1px solid #eee" }),
														}}
													>
														<Progress
															key={project.id}
															index={index}
															data={project}
															language={i18n.language}
															background={background && background(project)}
															{...progressCalculator(project)}
														/>
													</Box>
												);
											})}
									</Box>
								))}
						</Box>
					</Box>
				);
			})}
		</Box>
	);
};

export default GanttChart;
