import { Search } from "@mui/icons-material";
import {
	Backdrop,
	Box,
	CircularProgress,
	Container,
	IconButton,
	InputBase,
	Paper,
	Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { mainApi } from "../providers/api";
import PublicProjectCard from "../models/project/PublicCard";
import { useTranslation } from "react-i18next";

const ProjectList = () => {
	const { t } = useTranslation();
	const [searchParams, setSearchParams] = useSearchParams();
	const [projects, setProjects] = useState([]);
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(false);
	const [searchValue, setSearchValue] = useState(
		searchParams.get("name") || ""
	);
	const [search, setSearch] = useState(searchParams.get("name"));

	const fetchProject = async (search) => {
		setLoading(true);
		const projects = await mainApi({
			method: "POST",
			url: "/public/project",
			data: {
				find: search
					? {
							$or: [
								{ name: { $regex: search, $options: "i" } },
								{ "trans.us.name": { $regex: search, $options: "i" } },
							],
					  }
					: {},
				limit: 100,
				sort: { created: 1 },
			},
		});
		setProjects(projects.data.items);
		setTotal(projects.data.total);
		setLoading(false);
	};

	useEffect(() => {
		fetchProject(search);
	}, [search]);

	return (
		<Container>
			<Backdrop sx={{ color: "#fff", zIndex: 11 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<Paper
						component="form"
						onSubmit={(e) => {
							e.preventDefault();
							setSearchParams({ name: searchValue });
							setSearch(searchValue);
						}}
						sx={{
							p: "2px 4px",
							display: "flex",
							alignItems: "center",
							width: 300,
							my: 4,
						}}
					>
						<InputBase
							sx={{ ml: 1, flex: 1 }}
							placeholder={t("label.search")}
							value={searchValue}
							onChange={({ target }) => {
								setSearchValue(target.value);
							}}
						/>
						<IconButton type="submit" sx={{ p: "10px" }}>
							<Search />
						</IconButton>
					</Paper>
					<Typography>
						{t("label.total")}: {total}
					</Typography>
				</Box>
			</Box>
			<Box sx={{ pb: 4 }}>
				{projects.map((project) => (
					<PublicProjectCard
						key={project._id}
						search={search}
						{...project}
					></PublicProjectCard>
				))}
			</Box>
		</Container>
	);
};

export default ProjectList;
