import { Box, Typography } from "@mui/material";
import * as Yup from "yup";
import Field from "../../components/form/Field";
import Form from "../../components/form/Form";
import Group from "../../components/Group";
import { DEBUG } from "../../defines";
import { useAuth } from "../../providers/auth";
import { useNavigate } from "react-router-dom";

const TaskForm = () => {
	const navigate = useNavigate();
	const { fetchBadge } = useAuth();

	const form = {
		readonly: false,
		url: "notification",
		initial: {
			info: DEBUG
				? "Хөдөлмөр, нийгмийн хамгааллын яамны сайдын 2022 оны А дугаарт журмын дагуу хариуцаж байгаа төслүүдийн мэдээллийн шинэчлэлтийг төслийн мэдээллийн системд оруулна уу."
				: "",
		},
		validation: Yup.object({
			info: Yup.string().required("Тайлбар оруулна уу"),
		}),
		sx: { width: { md: 1, lg: 1 / 3 } },
		onSubmitted: () => {
			fetchBadge();
			navigate(-1);
		},
	};

	return (
		<Box>
			<Form {...form}>
				{({ disabled }) => {
					return (
						<Group>
							<Typography fontSize="small">
								Хадгалах товчийг дарснаар төслийн мэдээлэл шинэчлэх мэдэгдлийг
								төсөл хариуцсан мэргэжилтнүүдийн мэйл хаягт илгээгдэнэ.
							</Typography>
							<Field
								disabled={disabled}
								name="info"
								label="Тайлбар"
								rows="10"
							/>
						</Group>
					);
				}}
			</Form>
		</Box>
	);
};

export default TaskForm;
