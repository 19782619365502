import {
	Backdrop,
	Box,
	CircularProgress,
	Grid,
	Typography,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { mainApi } from "../../providers/api";
import ProjectThumb from "./Thumb";
import { useTranslation } from "react-i18next";

const LatestProjects = () => {
	const { t } = useTranslation();
	const [latest, setLatest] = useState([]);
	const [loading, setLoading] = useState(false);

	const fetchLatest = async () => {
		setLoading(true);
		const latest = await mainApi({
			method: "POST",
			url: "/public/project",
			data: { sort: { created: -1 }, limit: 3 },
		});
		setLatest(latest.data.items);
		setLoading(false);
	};

	useEffect(() => {
		fetchLatest();
	}, []);

	return (
		<Box>
			<Backdrop sx={{ color: "#fff", zIndex: 11 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Typography
				color="primary.main"
				fontWeight={"bold"}
				textAlign="center"
				fontSize={20}
				my={8}
			>
				{t("label.new_projects")}
			</Typography>
			<Grid container spacing={2}>
				{latest.map((project) => (
					<Grid key={project._id} item xs={12} sm={4}>
						<ProjectThumb {...project}></ProjectThumb>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default LatestProjects;
