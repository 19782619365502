import {
	FormGroup,
	FormControlLabel,
	Switch as MuiSwitch,
} from "@mui/material";

const Switch = ({ sx, value = false, disabled, onChange, label, ...props }) => {
	return (
		<FormGroup>
			<FormControlLabel
				sx={sx}
				disabled={disabled}
				control={
					<MuiSwitch
						{...props}
						checked={value}
						disabled={disabled}
						onChange={({ target }) => onChange(target.checked)}
					/>
				}
				label={label}
			/>
		</FormGroup>
	);
};

export default Switch;
