import {
	useState,
	useEffect,
	useRef,
	Children,
	isValidElement,
	cloneElement,
} from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { GOOGLE_MAP_CENTER, GOOGLE_MAP_KEY } from "../../defines";

const Map = ({ onClick, children, style, ...options }) => {
	const ref = useRef(null);
	const [map, setMap] = useState();

	useEffect(() => {
		if (ref.current && !map) {
			setMap(new window.google.maps.Map(ref.current, {}));
		}
	}, [ref, map]);

	useEffect(() => {
		if (map) {
			map.setOptions(options);
		}
	}, [map, options]);

	useEffect(() => {
		if (map) {
			["click"].forEach((eventName) =>
				window.google.maps.event.clearListeners(map, eventName)
			);
			if (onClick) {
				map.addListener("click", onClick);
			}
		}
	}, [map, onClick]);

	return (
		<>
			<div ref={ref} style={style} />
			{Children.map(children, (child) => {
				if (isValidElement(child)) {
					return cloneElement(child, { map });
				}
			})}
		</>
	);
};

const Marker = (options) => {
	const [marker, setMarker] = useState();

	useEffect(() => {
		if (!marker) {
			setMarker(new window.google.maps.Marker());
		}

		return () => {
			if (marker) {
				marker.setMap(null);
			}
		};
	}, [marker]);

	useEffect(() => {
		if (marker) {
			marker.setOptions(options);
		}
	}, [marker, options]);

	return null;
};

const PointSelect = ({
	value,
	defaultLng,
	defaultLat,
	disabled,
	onChange,
	height = 400,
}) => {
	const [point, setPoint] = useState();
	const [center, setCenter] = useState(GOOGLE_MAP_CENTER);

	const onClick = (e) => {
		if (!disabled) {
			onChange({
				type: "Point",
				coordinates: [e.latLng.lng(), e.latLng.lat()],
			});
			setCenter(e.latLng);
		}
	};

	useEffect(() => {
		if (value?.coordinates) {
			const [lng, lat] = value.coordinates;
			setPoint({ lng, lat });
		}
	}, [value?.coordinates]);

	useEffect(() => {
		if (defaultLng && defaultLat) {
			setCenter({ lng: defaultLng, lat: defaultLat });
		}
	}, [defaultLng, defaultLat]);

	return (
		<Wrapper apiKey={GOOGLE_MAP_KEY}>
			<Map
				center={center}
				onClick={onClick}
				zoom={12}
				style={{ height, width: "100%" }}
			>
				{point && point.lat && point.lng && <Marker position={point} />}
			</Map>
		</Wrapper>
	);
};

export default PointSelect;
