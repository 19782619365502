import { FormControlLabel, Radio, RadioGroup, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as CONSTS from "../../consts";
import { mainApi } from "../../providers/api";

const Radios = ({
	axio,
	axioAdapter,
	options = [],
	keyField = "_id",
	displayField = "name",
	path,
	lang = "mn",

	value,
	disabled,
	onChange,
	onChanged,

	...props
}) => {
	const { t } = useTranslation();
	const [items, setItems] = useState(options);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (onChanged) {
			const item = items.find((item) => value === item[keyField]);
			onChanged(item);
		}
		// eslint-disable-next-line
	}, [items, value]);

	useEffect(() => {
		if (Array.isArray(CONSTS[path]))
			setItems(
				CONSTS[path].map((item) => ({
					_id: item,
					name: t(`const.${path}.${item}`, { lng: lang }),
				}))
			);
		// eslint-disable-next-line
	}, [path, lang]);

	useEffect(() => {
		const fetchItems = async () => {
			setLoading(true);

			const response = await mainApi(axio);
			const data =
				response && (axioAdapter ? axioAdapter(response.data) : response.data);

			setItems(data);
			setLoading(false);
		};

		if (axio) fetchItems();
		// eslint-disable-next-line
	}, [axio]);

	if (loading)
		return (
			<Skeleton variant="rectangular" animation="wave" sx={{ height: 36.5 }} />
		);

	return (
		<RadioGroup {...props}>
			{items.map((item) => {
				const key = item[keyField];
				const display = item[displayField];
				const isActive = value === key;

				return (
					<FormControlLabel
						{...item}
						key={key}
						disabled={disabled}
						onClick={() => {
							!disabled && onChange(key);
						}}
						label={display}
						control={<Radio checked={isActive} />}
					>
						{display}
					</FormControlLabel>
				);
			})}
		</RadioGroup>
	);
};

export default Radios;
