import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";

import {
	Pie,
	PieChart,
	Cell,
	ResponsiveContainer,
	Sector,
	Legend,
} from "recharts";
import { Backdrop, CircularProgress } from "@mui/material";
import { mainApi } from "../../providers/api";

const colors = scaleOrdinal(schemeCategory10).range();

const renderActiveShape = (props) => {
	const RADIAN = Math.PI / 180;
	const {
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		startAngle,
		endAngle,
		fill,
		payload,
		percent,
	} = props;
	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius + 10) * cos;
	const sy = cy + (outerRadius + 10) * sin;
	const mx = cx + (outerRadius + 30) * cos;
	const my = cy + (outerRadius + 30) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	const ey = my;
	const textAnchor = cos >= 0 ? "start" : "end";

	return (
		<g>
			<text
				x={cx}
				y={cy}
				dy={8}
				textAnchor="middle"
				fill={fill}
				fontSize={12}
			></text>
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				startAngle={startAngle}
				endAngle={endAngle}
				fill={fill}
			/>
			<Sector
				cx={cx}
				cy={cy}
				startAngle={startAngle}
				endAngle={endAngle}
				innerRadius={outerRadius + 6}
				outerRadius={outerRadius + 10}
				fill={fill}
			/>
			<path
				d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
				stroke={fill}
				fill="none"
			/>
			<circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
			<text
				x={ex + (cos >= 0 ? 1 : -1) * 12}
				y={ey}
				textAnchor={textAnchor}
				fill="#333"
				fontSize={12}
			>
				{`${payload.name}: ${payload.value}`}
			</text>
			<text
				x={ex + (cos >= 0 ? 1 : -1) * 12}
				y={ey}
				dy={18}
				textAnchor={textAnchor}
				fill="#999"
				fontSize={12}
			>
				{`(${(percent * 100).toFixed(2)}%)`}
			</text>
		</g>
	);
};

const PChart = ({ type }) => {
	const { t } = useTranslation();
	const [index, setIndex] = useState(0);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const response = await mainApi(`/public/pie/${type}`);
			setData(
				response.data.map(({ name, value }) => ({
					name: t(`const.${type}.${name}`),
					value,
				}))
			);
			setLoading(false);
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			style={{
				height: "400px",
				width: "100%",
			}}
		>
			<Backdrop sx={{ color: "#fff", zIndex: 11 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<ResponsiveContainer>
				<PieChart>
					<Legend />
					<Pie
						data={data}
						dataKey="value"
						innerRadius="40%"
						outerRadius="60%"
						activeIndex={index}
						activeShape={renderActiveShape}
						onMouseEnter={(data, index) => {
							setIndex(index);
						}}
						isAnimationActive={true}
					>
						{data &&
							data.map((entry, index) => (
								<Cell key={`slice-${index}`} fill={colors[index % 10]} />
							))}
					</Pie>
				</PieChart>
			</ResponsiveContainer>
		</div>
	);
};

export default PChart;
