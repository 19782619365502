import { useContext, createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetFromStorage, RemoveFromStorage, SaveToStorage } from "./storage";
import { mainApi } from "./api";

window.addEventListener("storage", (event) => {
	if (event.key === "logged-out") {
		window.location = "/page/login";
		window.close();
	}
});

const AuthContext = createContext({
	user: null,
	badge: 0,
	login: () => {},
	logout: () => {},
	fetchBadge: () => {},
});

const AuthProvider = ({ children }) => {
	const navigate = useNavigate();
	const [user, setUser] = useState();
	const [badge, setBadge] = useState(0);

	const login = async (instance) => {
		setInstance(instance);
		navigate("/app/project");
	};

	const logout = () => {
		setInstance();
	};

	const fetchBadge = async () => {
		const count = await mainApi("/notification/my/count");
		setBadge(count.data);
	};

	const setInstance = (instance) => {
		if (instance) {
			SaveToStorage("refreshToken", instance.refreshToken);
			SaveToStorage("accessToken", instance.accessToken);
			setUser(instance.user);
		} else {
			RemoveFromStorage("refreshToken");
			RemoveFromStorage("accessToken");
			setUser(null);
		}
	};

	useEffect(() => {
		const refreshUserToken = async (token) => {
			if (token) {
				fetchBadge();
				try {
					const response = await mainApi({
						method: "POST",
						url: "/user/refresh",
						data: {
							token,
						},
					});
					response && setInstance(response.data);
				} catch {}
			}
		};

		refreshUserToken(GetFromStorage("refreshToken"));
	}, []);

	return (
		<AuthContext.Provider value={{ user, login, logout, badge, fetchBadge }}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => useContext(AuthContext);
export default AuthProvider;
