import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";

const Progress = ({ progress, height = 3 }) => {
	return (
		<Box sx={{ width: "100%", height, background: grey[300] }}>
			<Box
				sx={{
					width: `${progress}%`,
					height,
					background: "#2a57a4",
					zIndex: 1,
				}}
			></Box>
		</Box>
	);
};

export default Progress;
