import { Box, Link } from "@mui/material";

const DOCS = [{ name: "Систем ашиглах гарын авлага", src: "/pdf/guide.pdf" }];

const Guide = () => {
	return (
		<Box sx={{ width: "100%", height: 700 }}>
			{DOCS.map(({ name, src }) => (
				<Link
					key={name}
					sx={{ display: "block" }}
					color="inherit"
					target={"_blank"}
					href={src}
				>
					{name}
				</Link>
			))}
		</Box>
	);
};

export default Guide;
