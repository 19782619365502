import { Box } from "@mui/material";
import List from "../../components/List";

const DemoList = () => {
	return (
		<Box sx={{ p: 4 }}>
			<List
				sort={{ created: -1 }}
				add
				more
				url="/user/table"
				columns={[
					{
						field: "email",
						label: "Имэйл",
						filter: true,
					},
					{
						field: "firstname",
						label: "Нэр",
						filter: true,
					},
					{
						field: "position",
						label: "Албан тушаал",
						filter: true,
					},
					{
						field: "phone",
						label: "Утас",
						filter: true,
					},
				]}
			/>
		</Box>
	);
};

export default DemoList;
