import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	InputBase,
	Button,
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Toolbar,
	Paper,
	IconButton,
	CircularProgress,
	Backdrop,
	Typography,
} from "@mui/material";
import { Search, Add, KeyboardArrowRight } from "@mui/icons-material";

import { mainApi } from "../providers/api";
import { useModal } from "../providers/modal";
import Highlighter from "react-highlight-words";
import objectPath from "object-path";
import { DEBUG } from "../defines";

const cellTemplate = function (templateString, templateVars) {
	// eslint-disable-next-line
	return new Function("return `" + templateString + "`;").call(templateVars);
};

const highLighterWrapper = (wrap, search, children) => {
	if (!wrap) return children;

	return (
		<Highlighter searchWords={[search]} textToHighlight={children || ""}>
			{children}
		</Highlighter>
	);
};

const List = ({
	url,
	columns = [],
	add,
	more,
	moreText,
	renderList,
	renderHeader,
	find: propFind,
	sort: propSort,
	limit: propLimit = 20,
}) => {
	const { t } = useTranslation();
	const { toast } = useModal();

	const [order, setOrder] = useState();
	const [orderBy, setOrderBy] = useState();
	const [search, setSearch] = useState();
	const [searchValue, setSearchValue] = useState("");
	const [page, setPage] = useState(0);
	const [limit, setLimit] = useState(propLimit);
	const [loading, setLoading] = useState(false);

	const [items, setItems] = useState([]);
	const [total, setTotal] = useState(0);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setLimit(parseInt(event.target.value, 10));
		setPage(0);
	};

	const createSortHandler = (field) => () => {
		if (orderBy === field && order === "desc") {
			setOrder();
			setOrderBy();
			return;
		}

		const isAsc = orderBy === field && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(field);
	};

	useEffect(() => {
		const fetch = async () => {
			try {
				setLoading(true);
				const data = {
					offset: page,
					limit,
					sort: {
						...propSort,
						...(orderBy && { [orderBy]: order === "asc" ? 1 : -1 }),
					},
					find: {
						...propFind,
						...(search && {
							$or: columns
								.filter(({ filter }) => !!filter)
								.map(({ field }) => ({
									[field]: { $regex: search, $options: "i" },
								})),
						}),
					},
				};

				DEBUG && console.log(data);

				const { data: response } = await mainApi({
					method: "POST",
					url,
					data,
				});
				setItems(response.items);
				setTotal(response.total);
			} catch (error) {
				toast(t(error), "error");
			} finally {
				setLoading(false);
			}
		};

		fetch();
		// eslint-disable-next-line
	}, [url, columns, search, order, orderBy, page, limit]);

	return (
		<Box sx={{ width: "100%", position: "relative" }}>
			<Toolbar disableGutters={true}>
				<Box sx={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
					<Paper
						component="form"
						onSubmit={(e) => {
							e.preventDefault();
							setSearch(searchValue);
							setPage(0);
						}}
						sx={{
							p: "2px 4px",
							display: "flex",
							alignItems: "center",
							width: 300,
						}}
					>
						<InputBase
							sx={{ ml: 1, flex: 1 }}
							placeholder={t("label.search")}
							value={searchValue}
							onChange={({ target }) => {
								setSearchValue(target.value);
							}}
						/>
						<IconButton type="submit" sx={{ p: "10px" }}>
							<Search />
						</IconButton>
					</Paper>
				</Box>
				{renderHeader && renderHeader()}
				{add && (
					<Button
						variant="contained"
						endIcon={<Add />}
						component={Link}
						to="new"
					>
						<Typography sx={{ display: { xs: "none", md: "block" } }}>
							{t("button.add")}
						</Typography>
					</Button>
				)}
			</Toolbar>
			{loading && (
				<Backdrop sx={{ color: "#fff" }} open={loading}>
					<CircularProgress color="inherit" />
				</Backdrop>
			)}
			{renderList ? (
				renderList({ items, total })
			) : (
				<TableContainer>
					<Table sx={{ minWidth: 750 }}>
						<TableHead>
							<TableRow>
								{columns.map(({ field, right, label, sort, width }) => (
									<TableCell
										key={field}
										width={width}
										align={right ? "right" : "left"}
										sortDirection={orderBy === field ? order : false}
									>
										{sort ? (
											<TableSortLabel
												active={orderBy === field}
												direction={orderBy === field ? order : "asc"}
												onClick={createSortHandler(field)}
											>
												{label}
											</TableSortLabel>
										) : (
											label
										)}
									</TableCell>
								))}
								{more && <TableCell></TableCell>}
							</TableRow>
						</TableHead>
						<TableBody>
							{items.map((row) => {
								return (
									<TableRow hover key={row._id}>
										{columns.map(
											({ field, right, render, template, filter }) => {
												return (
													<TableCell
														key={field}
														align={right ? "right" : "left"}
													>
														{highLighterWrapper(
															filter,
															search,
															render
																? render(row)
																: template
																? cellTemplate(template, row)
																: objectPath.get(row, field)
														)}
													</TableCell>
												);
											}
										)}
										{more && (
											<TableCell align="right">
												<Button
													component={Link}
													to={
														typeof more === "function"
															? more(row)
															: `view/${row._id}`
													}
													variant="outlined"
													endIcon={<KeyboardArrowRight />}
												>
													{moreText || t("button.more")}
												</Button>
											</TableCell>
										)}
									</TableRow>
								);
							})}
							{total === 0 && (
								<TableRow>
									<TableCell colSpan={columns.length} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			<TablePagination
				rowsPerPageOptions={[20, 50, 100]}
				component="div"
				count={total}
				rowsPerPage={limit}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				labelRowsPerPage="Харуулах хэмжээ"
				labelDisplayedRows={({ from, to, count }) =>
					`${from} - ${to} Нийт: ${count}`
				}
			/>
		</Box>
	);
};

export default List;
