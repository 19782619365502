import {
	AccountBalance,
	Group,
	FolderCopy,
	AccountTree,
	Notifications,
} from "@mui/icons-material";

const superior_menu = [
	{
		label: "project",
		to: "/app/project",
		icon: <FolderCopy />,
	},
	{
		label: "organization",
		to: "/app/organization",
		icon: <AccountBalance />,
	},
	{
		label: "user",
		to: "/app/user",
		icon: <Group />,
	},
	{
		label: "category",
		to: "/app/category",
		icon: <AccountTree />,
	},
	{
		label: "notification",
		to: "/app/notification",
		icon: <Notifications />,
	},
];

const admin_menu = [
	{
		label: "project",
		to: "/app/project",
		icon: <FolderCopy />,
	},
	{
		label: "organization",
		to: "/app/organization",
		icon: <AccountBalance />,
	},
];

const MENU = {
	superior: superior_menu,
	admin: admin_menu,
};

export default MENU;
