import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Typography,
} from "@mui/material";
import objectPath from "object-path";
import { API_ROOT } from "../../defines";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { KeyboardArrowRight } from "@mui/icons-material";

const ProjectThumb = ({ _id, name, trans, funders, logo }) => {
	const { t, i18n } = useTranslation();
	let src =
		objectPath.get(logo, "0.id") || objectPath.get(funders, "0.logo.0.id");

	const image = src ? API_ROOT + "/" + src : "/images/placeholder.jpg";
	const { language } = i18n;
	const title = language === "mon" ? name : trans?.us?.name;
	const org = objectPath.get(
		funders,
		language === "mon" ? `0.name` : "0.trans.us.name"
	);

	return (
		<Card
			sx={{
				maxWidth: { sx: "100%", sm: 345 },
				minHeight: 370,
				flex: 1,
				display: "flex",
				flexDirection: "column",
				pt: 4,
				justifyContent: "space-between",
			}}
		>
			<Box sx={{ display: "flex", flexDirection: "column", flexShrink: 0 }}>
				<img
					style={{ height: 140, objectFit: "contain" }}
					src={image}
					alt={title}
					crossOrigin="anonymous"
				/>
				<Typography
					gutterBottom
					variant="body2"
					color="text.secondary"
					textAlign={"center"}
					component="div"
					sx={{ px: 4, mt: 1 }}
				>
					{org}
				</Typography>
			</Box>
			<Box sx={{ flexShrink: 1 }}>
				<CardContent>
					<Typography variant="body2">{title}</Typography>
				</CardContent>
				<CardActions sx={{ justifyContent: "end" }}>
					<Button
						size="small"
						component={Link}
						to={`/page/project/${_id}`}
						endIcon={<KeyboardArrowRight />}
					>
						{t("button.more")}
					</Button>
				</CardActions>
			</Box>
		</Card>
	);
};

export default ProjectThumb;
