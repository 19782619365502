import {
	Backdrop,
	Box,
	CircularProgress,
	Container,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import LatestProjects from "../models/project/Latest";
import { mainApi } from "../providers/api";
import { imageField } from "../utils";

const OrgItem = (org) => {
	const { t, i18n } = useTranslation();

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "start",
				mb: 4,
				pb: 4,
				borderBottom: "1px solid #f7f7f7",
			}}
		>
			<div id={org._id}></div>
			{imageField(org, "logo.0.id", {
				width: 150,
				border: "none",
				flexShrink: 0,
			})}
			<Box sx={{ ml: 2 }}>
				{i18n.language === "mon" ? org.name_mon : org.name_eng} ({org.total}{" "}
				{t("label.project_total")})
				<Box fontSize={"small"}>
					{Array.isArray(org.projects) &&
						org.projects.map(({ id, name }, index) => (
							<Box
								key={id}
								component={Link}
								to={`/page/project/${id}`}
								color="primary.main"
								display={"block"}
							>
								{index + 1}. {name[i18n.language]}
							</Box>
						))}
				</Box>
			</Box>
		</Box>
	);
};

const Organization = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [implementers, setImplementers] = useState([]);
	const [funders, setFunders] = useState([]);
	const [searchParams] = useSearchParams();
	const [selected, setSelected] = useState();

	useEffect(() => {
		const element = document.getElementById(selected);
		if (element) {
			const y = element.getBoundingClientRect().top + window.pageYOffset;

			window.scrollTo({ top: y, behavior: "smooth" });
		}
	}, [selected]);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const f = await mainApi(`/public/org/funders`);
			const i = await mainApi(`/public/org/implementers`);

			setFunders(f.data);
			setImplementers(i.data);
			setLoading(false);

			setSelected(searchParams.get("org_id"));
		};

		fetchData();
	}, [searchParams]);

	return (
		<Container>
			<Backdrop sx={{ color: "#fff", zIndex: 11 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Typography
				color="primary.main"
				fontWeight={"bold"}
				textAlign="center"
				fontSize={20}
				my={8}
			>
				{t("label.project_funders")}
			</Typography>
			<Box>
				{Array.isArray(funders) &&
					funders.map((org) => <OrgItem key={org._id} {...org} />)}
			</Box>
			<Typography
				color="primary.main"
				fontWeight={"bold"}
				textAlign="center"
				fontSize={20}
				my={8}
			>
				{t("label.project_implementers")}
			</Typography>
			<Box>
				{Array.isArray(implementers) &&
					implementers.map((org) => <OrgItem key={org._id} {...org} />)}
			</Box>

			<Box sx={{ bgcolor: "#f7f7f7", py: 5, pt: 2 }}>
				<Container>
					<LatestProjects></LatestProjects>
				</Container>
			</Box>
		</Container>
	);
};

export default Organization;
