import { Box, FormLabel, Typography } from "@mui/material";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Field from "../../components/form/Field";
import FormFieldArray from "../../components/form/FieldArray";
import Form from "../../components/form/Form";
import Group from "../../components/Group";
import { mainApi } from "../../providers/api";
import { useAuth } from "../../providers/auth";
import View from "./View";

const ProjectForm = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { user, fetchBadge } = useAuth();

	const form = {
		url: "project",
		id,
		readonly: ({ users } = {}) => {
			if (user.role === "superior") return false;
			if (Array.isArray(users) && users.includes(user.id)) return false;
			return true;
		},
		initial: {
			thumbnail: [],
			published: true,
			name: "",
			code: "",
			content: "",
			expected: "",
			result: "",

			trans: {
				us: {
					name: "",
					content: "",
				},
			},

			components: [],
			source: "",
			funders: [],
			budget: "",
			budget_percent: "",
			currency: "",
			implementers: [],

			status: "",
			start: null,
			end: null,
			progress: "",
			users: [],
			sectors: [],
			segments: [],
			location: [],

			socials: {
				website: "",
				email: "",
				phone: "",
				facebook: "",
				google: "",
				youtube: "",
				linkedin: "",
			},
			private_attachments: [],
			attachments: [],

			accessibility: {
				q1: {},
				q2: {},
				q3: {},
				q4: {},
				q5: {},
			},
		},
		validation:
			user.role === "superior"
				? Yup.object({ name: Yup.string().required("Нэр оруулна уу") })
				: Yup.object({
						name: Yup.string().required("Нэр оруулна уу"),
						purpose: Yup.string().required("Зорилго оруулна уу"),
						expected: Yup.string().required("Хүлээгдэж буй үр дүн оруулна уу"),
						result: Yup.string().required("Хэрэгжилтийн үр дүн оруулна уу"),
						content: Yup.string().required("Төслийн мэдээлэл оруулна уу"),

						trans: Yup.object({
							us: Yup.object({
								name: Yup.string().required("Нэр англиар оруулна уу"),
								purpose: Yup.string().required("Зорилго англиар оруулна уу"),
								expected: Yup.string().required(
									"Хүлээгдэж буй үр дүн англиар оруулна уу"
								),
								result: Yup.string().required(
									"Хэрэгжилтийн үр дүн англиар оруулна уу"
								),
								content: Yup.string().required(
									"Төслийн мэдээлэл англиар оруулна уу"
								),
							}),
						}),
						components: Yup.array().of(
							Yup.object({
								component: Yup.string().required("Бүрэлдэхүүн оруулна уу"),
								component_us: Yup.string().required(
									"Бүрэлдэхүүн англиар оруулна уу"
								),
								// component_result: Yup.string().required(
								// 	"Бүрэлдэхүүний хэрэгжилтийг оруулна уу"
								// ),
								// component_result_us: Yup.string().required(
								// 	"Бүрэлдэхүүний хэрэгжилтийг англиар оруулна уу"
								// ),
								progress: Yup.number()
									.required("Хэрэгжилтийн хувь оруулна уу")
									.min(0, "Хамгийн багадаа 0 байна")
									.max(100, "Хамгийн ихдээ 100 байна"),
							})
						),
						source: Yup.string()
							.nullable()
							.required("Санхүүжилтийн төрөл сонгоно уу"),
						funders: Yup.array()
							.of(Yup.string())
							.required()
							.min(1, "Санхүүжүүлэгч сонгоно уу"),
						budget: Yup.number().required("Санхүүжилтийн дүн оруулна уу"),
						// budget_percent: Yup.number()
						// 	.required("Санхүүжилтийн хувь оруулна уу")
						// 	.min(0, "Хамгийн багадаа 0 байна")
						// 	.max(100, "Хамгийн ихдээ 100 байна"),
						currency: Yup.string().nullable().required("Валют сонгоно уу"),

						status: Yup.string().nullable().required("Төлөв сонгоно уу"),
						start: Yup.date().required("Эхлэх огноо оруулна уу"),
						end: Yup.date().required("Дуусах огноо оруулна уу"),
						progress: Yup.number()
							.required("Хэрэгжилт оруулна уу")
							.min(0, "Хамгийн багадаа 0 байна")
							.max(100, "Хамгийн ихдээ 100 байна"),

						sectors: Yup.array()
							.of(Yup.string())
							.required()
							.min(1, "Чиглэл сонгоно уу"),
						segments: Yup.array()
							.of(Yup.string())
							.required()
							.min(1, "Зорилтот бүлэг сонгоно уу"),
						location: Yup.array()
							.of(Yup.string())
							.required()
							.min(1, "Байршил сонгоно уу"),
				  }),
		view: id
			? (props, fetching) => (fetching ? null : <View {...props} />)
			: null,
		editLabel: "Мэдээлэл оруулах",
		onSubmitted: id
			? async () => {
					const notif = searchParams.get("notif");
					if (notif) {
						await mainApi({
							url: `/notification/done/${notif}`,
							method: "PUT",
						});
						fetchBadge();
						navigate(-1);
					}
			  }
			: null,
	};

	return (
		<Box>
			<Form {...form}>
				{({ disabled, submitted, values, errors }) => {
					console.log(errors);
					return (
						<>
							<Box sx={{ display: { xs: "grid", lg: "flex" }, gap: 2 }}>
								<Group title="Үндсэн мэдээлэл">
									<Field
										disabled={disabled}
										name="published"
										label="Нийтлэх"
										as="switch"
									/>
									<Field
										disabled={disabled}
										name="thumbnail"
										label="Нүүр зураг"
										as="upload"
										root="project_thumbnail"
										submitted={submitted}
										height={150}
									/>
									<Field disabled={disabled} name="code" label="Код" />
									<Field disabled={disabled} name="name" label="Нэр" />
									<Field
										disabled={disabled}
										name="trans.us.name"
										label="Нэр /English/"
									/>

									<Field
										disabled={disabled}
										name="purpose"
										label="Төслийн зорилго"
										rows="5"
									/>
									<Field
										disabled={disabled}
										name="trans.us.purpose"
										label="Төслийн зорилго /English/"
										rows="5"
									/>
								</Group>
								<Group title="Санхүүжилтийн мэдээлэл">
									<Field
										disabled={disabled}
										name="source"
										label="Санхүүжилтийн төрөл"
										as="buttons"
										path="source"
									/>
									<Field
										disabled={disabled}
										name="funders"
										label="Санхүүжүүлэгч байгууллага"
										as="select"
										axio="/org/select/funder"
										multi={true}
									/>
									<Box sx={{ display: "flex", gap: 2 }}>
										<Field
											disabled={disabled}
											name="budget"
											label="Төслийн төсөвт өртөг"
											as="masked"
											sx={{ flex: 1 }}
										/>
										<Field
											disabled={disabled}
											name="currency"
											label="Валют"
											as="select"
											axio="/category/select/currency"
											sx={{ width: 200 }}
										/>
									</Box>
									<Field
										disabled={disabled}
										name="budget_percent"
										label="Зарцуулалтын дүн"
										as="masked"
										sx={{ flex: 1 }}
									/>
									<Field
										disabled={disabled}
										name="implementers"
										label="Үндсэн хэрэгжүүлэгч"
										as="select"
										axio="/org/select/implementer"
										multi={true}
									/>
								</Group>
							</Box>
							<Box sx={{ display: { xs: "grid", lg: "flex" }, gap: 2, my: 2 }}>
								<Group title="Хэрэгжилтийн мэдээлэл">
									<Field
										disabled={disabled}
										name="users"
										label="Хариуцах мэргэжилтэн"
										as="select"
										axio="/user/select/all"
										displayField="_name"
										multi={true}
									/>
									<Field
										disabled={disabled}
										name="status"
										label="Төлөв"
										as="radios"
										path="status"
									/>
									<Box sx={{ display: "flex", gap: 2 }}>
										<Field
											disabled={disabled}
											name="start"
											label="Эхлэх"
											as="date"
											sx={{ flex: 1 }}
										/>
										<Field
											disabled={disabled}
											name="end"
											label="Дуусах"
											as="date"
											sx={{ flex: 1 }}
										/>
									</Box>
									<Field
										disabled={disabled}
										name="sectors"
										label="Чиглэл"
										as="select"
										axio="/category/select/sector"
										multi={true}
									/>
									<Field
										disabled={disabled}
										name="segments"
										label="Зорилтот бүлэг"
										as="select"
										axio="/category/select/segment"
										multi={true}
									/>
									<Field
										disabled={disabled}
										name="location"
										label="Хэрэгжих байршил"
										as="select"
										path="location"
										open={true}
										multi={true}
									/>
									<Field
										disabled={disabled}
										name="expected"
										label="Хүлээгдэж буй үр дүн"
										rows="5"
									/>
									<Field
										disabled={disabled}
										name="trans.us.expected"
										label="Хүлээгдэж буй үр дүн /English/"
										rows="5"
									/>
									<Field
										disabled={disabled}
										name="result"
										label="Хэрэгжилтийн байдал"
										rows="5"
									/>
									<Field
										disabled={disabled}
										name="trans.us.result"
										label="Хэрэгжилтийн байдал /English/"
										rows="5"
									/>
									<Field
										disabled={disabled}
										name="progress"
										label="Хэрэгжилтийн хувь"
										type="number"
									/>
								</Group>
								<Group title="Мэдээллийн сурвалж">
									<Field
										disabled={disabled}
										name="keywords"
										label="Түлхүүр үгнүүд"
										rows="5"
									/>
									<Field
										disabled={disabled}
										name="socials.website"
										label="Веб сайтын линк"
									/>
									<Field
										disabled={disabled}
										name="socials.email"
										label="Имэйл"
									/>
									<Field
										disabled={disabled}
										name="socials.phone"
										label="Утас"
									/>
									<Field
										disabled={disabled}
										name="socials.facebook"
										label="Фэйсбүүк хуудас"
									/>
									<Field
										disabled={disabled}
										name="socials.google"
										label="Google"
									/>
									<Field
										disabled={disabled}
										name="socials.youtube"
										label="Youtube channel"
									/>
									<Field
										disabled={disabled}
										name="socials.linkedin"
										label="LinkedIn"
									/>
									<Field
										disabled={disabled}
										name="attachments"
										label="Нийтэд ил харагдах файлууд"
										as="upload"
										root="project_attachments"
										submitted={submitted}
										fileSize={50}
										height={150}
										accept="image/*,application/pdf"
										count={100}
									/>
									<Field
										disabled={disabled}
										name="private_attachments"
										label="Дотоод хэрэгцээний файлууд"
										as="upload"
										root="private_attachments"
										submitted={submitted}
										fileSize={10}
										height={150}
										accept="*"
										count={30}
									/>
								</Group>
							</Box>
							<Box sx={{ display: { xs: "grid", lg: "flex" }, gap: 2, mb: 2 }}>
								<Group>
									<Field
										disabled={disabled}
										name="content"
										label="Төслийн мэдээлэл"
										placeholder="Мэдээлэл бичнэ үү"
										as="rich"
									/>
								</Group>
								<Group>
									<Field
										disabled={disabled}
										name="trans.us.content"
										label="Төслийн мэдээлэл /English/"
										placeholder="Мэдээлэл бичнэ үү"
										as="rich"
									/>
								</Group>
							</Box>
							<Group>
								<FormLabel>Бүрэлдэхүүн хэсгүүд</FormLabel>
								<FormFieldArray
									name="components"
									items={values.components}
									disabled={disabled}
									init={{
										component: "",
										component_us: "",
										component_result: "",
										component_result_us: "",
										progress: 100,
									}}
								>
									{(item, index) => {
										return (
											<Box sx={{ display: "flex", gap: 2 }}>
												<Box
													sx={{
														flex: 1,
														gap: 2,
														display: "flex",
														flexDirection: "column",
													}}
												>
													<Box sx={{ flex: 1, display: "flex", gap: 2 }}>
														<Field
															sx={{ flex: 1 }}
															disabled={disabled}
															name={`components.${index}.component`}
															label="Бүрэлдэхүүн"
															placeholder="Мэдээлэл бичнэ үү"
															rows={5}
														/>
														<Field
															sx={{ flex: 1 }}
															disabled={disabled}
															name={`components.${index}.component_us`}
															label="Бүрэлдэхүүн /English/"
															placeholder="Мэдээлэл бичнэ үү"
															rows={5}
														/>
													</Box>
													<Box sx={{ flex: 1, display: "flex", gap: 2 }}>
														<Field
															sx={{ flex: 1 }}
															disabled={disabled}
															name={`components.${index}.component_result`}
															label="Хэрэгжилтийн үр дүн"
															placeholder="Мэдээлэл бичнэ үү"
															rows={5}
														/>
														<Field
															sx={{ flex: 1 }}
															disabled={disabled}
															name={`components.${index}.component_result_us`}
															label="Хэрэгжилтийн үр дүн /English/"
															placeholder="Мэдээлэл бичнэ үү"
															rows={5}
														/>
													</Box>
												</Box>
												<Field
													sx={{ width: 200 }}
													disabled={disabled}
													name={`components.${index}.progress`}
													label="Хэрэгжилт"
													type="number"
												/>
											</Box>
										);
									}}
								</FormFieldArray>
							</Group>
							<Box
								sx={{
									display: { xs: "grid", lg: "flex" },
									gap: 2,
									mt: 2,
								}}
							>
								<Group title="Хүртээмжтэй байдлын үнэлгээний асуулга">
									<Typography>
										1. Төслийн үйл ажиллагаа хэрэгжиж буй цэгт төслийн үр шимийг
										хүртэгсдийн хэдэн хувь нь 15-34 насны залуучууд байна вэ?
									</Typography>
									<Box sx={{ gap: 2, display: "flex" }}>
										<Field
											disabled={disabled}
											name="accessibility.q1.male"
											label="Эрэгтэй"
											type="number"
										/>
										<Field
											disabled={disabled}
											name="accessibility.q1.female"
											label="Эмэгтэй"
											type="number"
										/>
									</Box>
									<Typography sx={{ mt: 2 }}>
										2. Бүс нутагт байгаа ажил хайгч ажилгүй иргэдийн хүйс хэдэн
										хувь байна вэ?
									</Typography>
									<Box sx={{ gap: 2, display: "flex" }}>
										<Field
											disabled={disabled}
											name="accessibility.q2.male"
											label="Эрэгтэй"
											type="number"
										/>
										<Field
											disabled={disabled}
											name="accessibility.q2.female"
											label="Эмэгтэй"
											type="number"
										/>
									</Box>
									<Typography sx={{ mt: 2 }}>
										3. Төслийн үйл ажиллагаа хөгжлийн бэрхшээлтэй иргэд шууд
										чиглэсэн эсэх?
									</Typography>
									<Field
										disabled={disabled}
										name="accessibility.q3"
										as="radios"
										path="acc3"
									/>
									<Typography sx={{ mt: 2 }}>
										4. Хөгжлийн бэрхшээлтэй иргэдийн хэдэн хувийг хамарч байгаа
										вэ?
									</Typography>
									<Box sx={{ gap: 2, display: "flex" }}>
										<Field
											disabled={disabled}
											name="accessibility.q4.male"
											label="Эрэгтэй"
											type="number"
										/>
										<Field
											disabled={disabled}
											name="accessibility.q4.female"
											label="Эмэгтэй"
											type="number"
										/>
									</Box>
									<Typography sx={{ mt: 2 }}>
										5. Төслийн хэрэгжүүлж буй аливаа үйл ажиллагаа (сургалт,
										сурталчилгаа, контент, барилга байгууламж гэх мэт) нь
										хөгжлийн бэрхшээлтэй иргэдэд хүртээмжтэй байхаар хийгдсэн
										эсэх?
									</Typography>
									<Field
										disabled={disabled}
										name="accessibility.q5"
										as="radios"
										path="acc5"
									/>
								</Group>
							</Box>
						</>
					);
				}}
			</Form>
		</Box>
	);
};

export default ProjectForm;
