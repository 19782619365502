import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { SearchOff as Icon } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import Back from "../components/buttons/Back";

const NotFound = () => {
	const { t } = useTranslation();

	return (
		<Box
			sx={{
				flex: 1,
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				color: grey[700],
			}}
		>
			<Icon sx={{ fontSize: 120 }}></Icon>
			<Typography sx={{ mb: 5 }}>404 {t("error.404")}</Typography>
			<Back />
		</Box>
	);
};

export default NotFound;
