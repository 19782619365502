import { KeyboardArrowRight } from "@mui/icons-material";
import {
	Box,
	Button,
	Card,
	CardContent,
	CardMedia,
	Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { imageField, imageUrl } from "../../utils";
import Progress from "../../components/Progress";
import Highlighter from "react-highlight-words";

const PublicProjectCard = ({
	_id,
	progress,
	name,
	trans,
	thumbnail,
	_start,
	_end,
	funders,
	search,
}) => {
	const { t } = useTranslation();

	return (
		<Card sx={{ display: "flex", mb: 4 }}>
			<Box>
				<CardMedia
					component="img"
					sx={{
						width: 200,
						display: { xs: "none", sm: "block" },
						objectFit: "contain",
						objectPosition: "top",
					}}
					image={imageUrl(thumbnail)}
					alt={name}
					crossOrigin="anonymous"
				/>
			</Box>
			<CardContent
				sx={{
					flex: 1,
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
				}}
			>
				<Box>
					<Typography variant="subtitle1" sx={{ lineHeight: 1.2 }}>
						<Highlighter searchWords={[search]} textToHighlight={name}>
							{name}
						</Highlighter>
					</Typography>
					<Typography
						variant="subtitle1"
						color="text.secondary"
						fontSize="small"
						sx={{ lineHeight: 1, my: 2 }}
					>
						<Highlighter
							searchWords={[search]}
							textToHighlight={trans?.us?.name}
						>
							{trans?.us?.name}
						</Highlighter>
					</Typography>
				</Box>
				<Box sx={{ display: "flex", flexDirection: "column" }}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							fontSize: 11,
						}}
					>
						<Box>{_start}</Box>
						<Box>{_end}</Box>
					</Box>
					<Progress progress={progress}></Progress>
					<Box
						sx={{
							mt: 2,
							display: "flex",
							flexWrap: "wrap",
							justifyContent: "space-between",
							alignItems: "center",
							gap: 1,
						}}
					>
						<Box sx={{ display: "flex", gap: 1 }}>
							{funders.map((funder) => {
								return imageField(funder, "logo.0.id", { height: 30 });
							})}
						</Box>
						<Button component={Link} to={_id} endIcon={<KeyboardArrowRight />}>
							{t("button.more")}
						</Button>
					</Box>
				</Box>
			</CardContent>
		</Card>
	);
};

export default PublicProjectCard;
