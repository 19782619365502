import * as React from "react";
import { NumericFormat } from "react-number-format";
import TextField from "@mui/material/TextField";

const Masked = ({
	sx,
	label,
	value = "",
	name,
	disabled,
	onChange,
	errored,
	helper,
	placeholder,
}) => {
	return (
		<NumericFormat
			sx={sx}
			label={label}
			defaultValue={value}
			disabled={disabled}
			name={name}
			onValueChange={({ value }) => onChange(value)}
			customInput={TextField}
			error={errored}
			helperText={helper}
			placeholder={placeholder || label}
			thousandSeparator
		/>
	);
};

export default Masked;
