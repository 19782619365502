import {
	Email,
	Facebook,
	FilePresent,
	Google,
	LinkedIn,
	Phone,
	Print,
	Public,
	YouTube,
} from "@mui/icons-material";
import {
	Box,
	Card,
	Grid,
	Button,
	Slider,
	Typography,
	Divider,
} from "@mui/material";
import {
	EmailIcon,
	EmailShareButton,
	FacebookIcon,
	FacebookShareButton,
	TwitterIcon,
	TwitterShareButton,
} from "react-share";
import objectPath from "object-path";
import { useTranslation } from "react-i18next";
import { imageField, imageUrl, thousandSeparator } from "../../utils";
import { styled } from "@mui/material/styles";
import { API_ROOT, DOMAIN } from "../../defines";
import QRCode from "react-qr-code";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { Link } from "react-router-dom";

const SOCIALS = [
	{ field: "website", icon: <Public color="primary" /> },
	{
		field: "email",
		icon: <Email color="primary" />,
		linker: (link) => `mailto:${link}`,
	},
	{
		field: "phone",
		icon: <Phone color="primary" />,
		linker: (link) => `tel:${link}`,
	},
	{ field: "facebook", icon: <Facebook color="primary" /> },
	{ field: "google", icon: <Google color="primary" /> },
	{ field: "youtube", icon: <YouTube color="primary" /> },
	{ field: "linkedin", icon: <LinkedIn color="primary" /> },
];

const PrettoSlider = styled(Slider)({
	color: "#2a57a4 !important",
	height: 8,
	"& .MuiSlider-track": {
		border: "none",
	},
	"& .MuiSlider-thumb": {
		height: 24,
		width: 24,
		backgroundColor: "#fff",
		border: "2px solid currentColor",
		"&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
			boxShadow: "inherit",
		},
		"&:before": {
			display: "none",
		},
	},
	"& .MuiSlider-valueLabel": {
		lineHeight: 1.2,
		fontSize: 12,
		background: "unset",
		padding: 0,
		width: 32,
		height: 32,
		borderRadius: "50% 50% 50% 0",
		backgroundColor: "#2a57a4",
		transformOrigin: "bottom left",
		transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
		"&:before": { display: "none" },
		"&.MuiSlider-valueLabelOpen": {
			transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
		},
		"& > *": {
			transform: "rotate(45deg)",
		},
	},
});

const renderAttachment = ({ id, name }) => {
	return (
		<Box
			key={id}
			component={Link}
			to={API_ROOT + "/" + id}
			target={"_blank"}
			sx={{ color: "gray", width: 100, textAlign: "center", fontSize: 10 }}
		>
			<FilePresent sx={{ width: 100, height: 100 }}></FilePresent>
			<Box>{name}</Box>
		</Box>
	);
};

const renderFiled = (value, label, t, inline) => {
	return (
		<Box sx={{ mb: 2, ...(inline ? { display: "flex" } : null) }}>
			<Typography
				sx={{
					flexShrink: 0,
					fontWeight: "bold",
					...(inline ? { width: 250 } : null),
				}}
			>
				{t(label)}
			</Typography>
			<Typography
				sx={{
					whiteSpace: "pre-wrap",
				}}
			>
				{value || t("label.empty_message")}
			</Typography>
		</Box>
	);
};

const ProjectView = ({ pub, ...data }) => {
	const { t, i18n } = useTranslation();

	const {
		thumbnail,
		code,
		name,
		purpose,
		expected,
		result,
		content,
		trans,
		components,
		source,
		funders = [],
		budget,
		budget_percent,
		currency,
		implementers = [],
		status,
		_start,
		_end,
		progress,
		sectors = [],
		segments = [],
		location = [],
		socials,
		private_attachments = [],
		attachments = [],
	} = data;

	const { language } = i18n;
	const _name = language === "mon" ? name : objectPath.get(trans, "us.name");
	const _thumbnail = imageUrl(thumbnail);
	const _budget = `${thousandSeparator(budget)} ${objectPath.get(
		currency,
		language === "mon" ? "name" : "trans.us.name"
	)}`;
	const link = `${DOMAIN}/page/project/${data.id}`;
	const _content =
		language === "mon" ? content : objectPath.get(trans, "us.content");
	const printRef = useRef();

	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "flex-end",
					gap: 1,
					alignItems: "center",
					mb: 5,
				}}
			>
				<ReactToPrint
					trigger={() => (
						<Button variant="outlined" startIcon={<Print />} onClick={() => {}}>
							<Typography sx={{ display: { xs: "none", md: "block" } }}>
								{t("button.print")}
							</Typography>
						</Button>
					)}
					content={() => printRef.current}
				/>
			</Box>
			<Box ref={printRef} sx={{ p: 5 }}>
				<Typography variant="h5" sx={{ textAlign: "center" }}>
					{_name}
				</Typography>
				{code && (
					<Typography variant="h6" sx={{ textAlign: "center" }}>
						/{code}/
					</Typography>
				)}
				<Box sx={{ display: "flex", my: 10 }}>
					<Box sx={{ mr: 2 }}>
						<img
							src={_thumbnail}
							alt={name}
							crossOrigin="anonymous"
							style={{ width: 100 }}
						/>
					</Box>
					<Box sx={{ flex: 1, position: "relative" }}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							<Typography sx={{ fontSize: "12px", mb: -1 }}>
								{_start}
							</Typography>
							<Typography sx={{ fontSize: "12px", mb: -1 }}>{_end}</Typography>
						</Box>
						<Box sx={{ px: 2, width: 1 }}>
							<PrettoSlider
								disabled={true}
								color="primary"
								defaultValue={progress || 0}
								valueLabelFormat={(v) => `${v}%`}
								getAriaValueText={(v) => `${v}%`}
								step={10}
								valueLabelDisplay="on"
								marks={[0, 20, 40, 60, 80, 100].map((v) => ({
									value: v,
									label: `${v}%`,
								}))}
							/>
						</Box>
					</Box>
				</Box>
				<Grid container spacing={4}>
					<Grid item xs={12} lg={8}>
						{renderFiled(
							t(`const.status.${status}`),
							"project.status",
							t,
							true
						)}
						{renderFiled(_start, "project.start", t, true)}
						{renderFiled(_end, "project.end", t, true)}
						{renderFiled(
							progress && `${progress}%`,
							"project.progress",
							t,
							true
						)}
						<Divider sx={{ my: 5 }}></Divider>
						{renderFiled(
							Array.isArray(sectors) &&
								sectors
									.map(({ name, trans }) =>
										language === "mon" ? name : trans?.us?.name
									)
									.join(", "),
							"project.sectors",
							t,
							true
						)}
						{renderFiled(
							Array.isArray(segments) &&
								segments
									.map(({ name, trans }) =>
										language === "mon" ? name : trans?.us?.name
									)
									.join(", "),
							"project.segments",
							t,
							true
						)}
						{renderFiled(
							Array.isArray(location) &&
								location
									.map((location) => t(`const.location.${location}`))
									.join(", "),
							"project.location",
							t,
							true
						)}
						<Divider sx={{ my: 5 }}></Divider>
						{renderFiled(
							Array.isArray(funders) &&
								funders
									.map(({ name, trans }) =>
										language === "mon" ? name : trans?.us?.name
									)
									.join(", "),
							"project.funders",
							t,
							true
						)}
						{renderFiled(
							Array.isArray(implementers) &&
								implementers
									.map(({ name, trans }) =>
										language === "mon" ? name : trans?.us?.name
									)
									.join(", "),
							"project.implementers",
							t,
							true
						)}
						{!pub &&
							renderFiled(
								budget &&
									`${thousandSeparator(
										budget,
										language === "mon"
											? currency.name
											: currency?.trans?.us?.name
									)}`,
								"project.budget",
								t,
								true
							)}
						{renderFiled(
							t(`const.source.${source}`),
							"project.source",
							t,
							true
						)}
						{!pub &&
							renderFiled(
								budget_percent &&
									`${thousandSeparator(
										budget_percent,
										language === "mon"
											? currency.name
											: currency?.trans?.us?.name
									)}`,
								"project.budget_percent",
								t,
								true
							)}
						{/* {renderFiled(
							budget_percent && `${budget_percent}%`,
							"project.budget_percent",
							t,
							true
						)} */}
						<Box
							sx={{
								my: 5,
								display: "flex",
								flexWrap: "wrap",
								gap: 2,
								alignItems: "center",
								justifyContent: "start",
							}}
						>
							{Array.isArray(funders) &&
								funders.map((org) =>
									imageField(org, "logo.0.id", {
										height: 40,
										border: "none",
									})
								)}
							{Array.isArray(implementers) &&
								implementers.map((org) =>
									imageField(org, "logo.0.id", {
										height: 40,
										border: "none",
									})
								)}
						</Box>
						{renderFiled(
							t(language === "mon" ? purpose : trans?.us?.purpose),
							"project.purpose",
							t
						)}
						{renderFiled(
							t(language === "mon" ? expected : trans?.us?.expected),
							"project.expected",
							t
						)}
						{renderFiled(
							t(language === "mon" ? result : trans?.us?.result),
							"project.result",
							t
						)}
						<div
							dangerouslySetInnerHTML={{
								__html: _content,
							}}
						></div>
						<Divider sx={{ my: 5 }} />
						<Box
							sx={{
								display: "flex",
								alignItems: "start",
								flexDirection: "column",
							}}
						>
							{SOCIALS.map(({ field, icon, linker }) => {
								const value = objectPath.get(socials, field);
								if (!value) return null;

								return (
									<Button
										key={field}
										startIcon={icon}
										color="primary"
										href={linker ? linker(value) : `http://${value}`}
										target={linker ? "_self" : "_blank"}
									>
										{value}
									</Button>
								);
							})}
						</Box>
						<Divider sx={{ my: 5 }} />
						<Box sx={{ fontWeight: "bold" }}>{t("project.components")}</Box>
						{Array.isArray(components) &&
							components.map(
								(
									{
										component,
										component_result,
										component_us,
										component_result_us,
										progress: _progress,
									},
									index
								) => {
									const _comp = language === "mon" ? component : component_us;
									const _comp_result =
										language === "mon" ? component_result : component_result_us;

									return (
										<Box key={index} sx={{ mb: 4 }}>
											<Box sx={{ mb: 1, whiteSpace: "pre-wrap" }}>{_comp}</Box>
											{_progress && (
												<Box sx={{ fontStyle: "italic", mb: 1 }}>
													{t("project.component_result")}{" "}
													{_progress && `${_progress}%`}
												</Box>
											)}
											<Box sx={{ ml: 2, whiteSpace: "pre-wrap" }}>
												{_comp_result}
											</Box>
										</Box>
									);
								}
							)}
						<Divider sx={{ my: 5 }} />
						{attachments?.length > 0 && (
							<Box
								sx={{ display: "flex", gap: 1, flexWrap: "wrap", mb: 2 }}
								fontSize="small"
							>
								{attachments.map(renderAttachment)}
							</Box>
						)}
						{!pub && private_attachments?.length > 0 && (
							<Box
								sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}
								fontSize="small"
							>
								{private_attachments.map(renderAttachment)}
							</Box>
						)}
					</Grid>
					<Grid item xs={12} lg={4}>
						<Box
							sx={{
								position: { sm: "relative", md: "sticky" },
								top: "30%",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Card sx={{ maxWidth: 300, p: 5, textAlign: "center" }}>
								<b>{t("label.share")}</b>
								<Box sx={{ my: 2 }}>
									<QRCode value={link} size={100} title={_name} />
								</Box>
								<Box sx={{ display: "inline-flex", gap: 1 }}>
									<TwitterShareButton url={link} title={_name}>
										<TwitterIcon size={33} />
									</TwitterShareButton>
									<FacebookShareButton url={link} quote={_name}>
										<FacebookIcon size={33} />
									</FacebookShareButton>
									<EmailShareButton url={link} subject={_name}>
										<EmailIcon size={33} />
									</EmailShareButton>
								</Box>
							</Card>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default ProjectView;
