import {
	Box,
	IconButton,
	ImageList,
	ImageListItem,
	ImageListItemBar,
} from "@mui/material";
import {
	Delete as DeleteIcon,
	AttachFile as FileIcon,
	Add as AddIcon,
} from "@mui/icons-material";
import { useModal } from "../../providers/modal";
import { mainApi } from "../../providers/api";
import { useRef, useState } from "react";
import { API_ROOT } from "../../defines";
import { grey } from "@mui/material/colors";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";

const FileUpload = ({
	disabled,
	value = [],
	onChange,
	root,
	name,
	fileSize = 3,
	count = 1,
	height = 150,
	accept = "image/*",
	submitted,
}) => {
	const limit = fileSize * 1024 * 1024;
	const { toast } = useModal();
	const [loading, setLoading] = useState(false);
	const container = useRef();
	const [col, setCol] = useState(3);
	const [deleteds, setDeleteds] = useState([]);

	const fileSelected = async (files) => {
		try {
			const total = value.length + files.length;
			if (total > count) {
				throw new Error(`Хамгийн ихдээ ${count} файл сонгоно`, "error");
			}

			let data = new FormData();
			Array.from(files).forEach((file) => {
				const { size, name } = file;
				if (size > limit) {
					throw new Error(`"${name}" файл ${fileSize} MB-с их байна`, "error");
				}
				data.append("upload", file);
			});

			setLoading(true);
			const response = await mainApi({
				url: `/file/fs/${root || name}`,
				method: "POST",
				data: data,
			});
			if (response) {
				onChange([...(value || []), ...response.data]);
			}
		} catch (error) {
			toast(error.message, "error");
		} finally {
			setLoading(false);
		}
	};

	const deleteItem = (index, { id }) => {
		const tmp = [...value];

		tmp.splice(index, 1);

		onChange(tmp);

		setDeleteds([...deleteds, id]);
	};

	const preiveItem = () => {};

	useEffect(() => {
		setCol(parseInt(container.current.offsetWidth / height));
	}, [container, height]);

	useEffect(() => {
		const deleteFiles = async () => {
			await mainApi({
				method: "DELETE",
				url: "/file/fs/",
				data: { deleteds },
			});
			setDeleteds([]);
		};

		if (submitted && deleteds.length > 0) {
			deleteFiles();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submitted]);

	return (
		<Box ref={container}>
			<ImageList cols={col}>
				{Array.isArray(value) &&
					value.map((item, index) => {
						const { id, type, name } = item;
						const src = API_ROOT + "/" + id;

						return (
							<ImageListItem key={id} sx={{ overflow: "hidden" }}>
								{type === "image" ? (
									<img
										src={src}
										alt={item.name}
										loading="lazy"
										crossOrigin="anonymous"
										onClick={() => {
											preiveItem(item);
										}}
									/>
								) : (
									<Box
										sx={{
											cursor: "pointer",
											display: "flex",
											height: 1,
											alignItems: "center",
											justifyContent: "center",
											textAlign: "center",
											fontSize: 10,
											position: "relative",
											border: 1,
											borderColor: grey[100],
										}}
										onClick={() => {
											preiveItem(item);
										}}
									>
										<FileIcon
											sx={{
												position: "absolute",
												fontSize: 40,
												opacity: 0.2,
											}}
										/>
										{name}
									</Box>
								)}
								{!disabled && (
									<ImageListItemBar
										actionIcon={
											<IconButton
												sx={{ color: "rgba(255, 255, 255, 0.9)" }}
												onClick={() => {
													deleteItem(index, item);
												}}
											>
												<DeleteIcon />
											</IconButton>
										}
									/>
								)}
							</ImageListItem>
						);
					})}
				{!disabled && count > value?.length && (
					<ImageListItem>
						<LoadingButton
							color="grey"
							sx={{
								height: 1,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
							variant="contained"
							component="label"
							loading={loading}
						>
							<AddIcon />
							<input
								type="file"
								accept={accept}
								hidden
								multiple={count > 1}
								onChange={({ target }) => {
									fileSelected(target.files);
								}}
							/>
						</LoadingButton>
					</ImageListItem>
				)}
			</ImageList>
		</Box>
	);
};

export default FileUpload;
