import { Box } from "@mui/material";
import List from "../../components/List";

const CategoryList = () => {
	return (
		<Box sx={{ p: 4 }}>
			<List
				sort={{ created: -1 }}
				add
				more
				url="/category/table"
				columns={[
					{
						field: "type",
						label: "Төрөл",
						filter: true,
					},
					{
						field: "name",
						label: "Ангилал",
						filter: true,
					},
					{
						field: "trans.us.name",
						// eslint-disable-next-line
						template: "${this.trans?.us?.name}",
						label: "Ангилал /English/",
						filter: true,
					},
				]}
			/>
		</Box>
	);
};

export default CategoryList;
