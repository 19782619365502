import { Card, Typography } from "@mui/material";

const Group = ({ children, title, sx = { flex: 1 } }) => {
	return (
		<Card sx={{ p: 2, display: "grid", gap: 2, alignContent: "start", ...sx }}>
			{title && (
				<Typography component="h2" variant="h6" color="primary" gutterBottom>
					{title}
				</Typography>
			)}
			{children}
		</Card>
	);
};

export default Group;
