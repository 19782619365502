import {
	FormControl,
	FormHelperText,
	FormLabel,
	TextField,
} from "@mui/material";
import { useField, Field as FormikField } from "formik";
import DatePicker from "../inputs/DatePicker";
import Buttons from "../inputs/Buttons";
import Radios from "../inputs/Radios";
import CheckBox from "../inputs/CheckBox";
import Switch from "../inputs/Switch";
import RichEditor from "../inputs/RichEditor";
import Select from "../inputs/Select";
import FileUpload from "../inputs/FileUpload";
import PointSelect from "../inputs/PointSelect";
import Masked from "../inputs/Masked";

const Field = ({ as, sx, label, placeholder, disabled, rows, ...props }) => {
	const [
		{ name, value, onChange },
		{ touched, error, initialValue },
		{ setValue },
	] = useField(props);

	let errored = touched && !!error;
	let helper = touched && error;

	if (!as)
		return (
			<FormikField
				as={TextField}
				sx={sx}
				name={name}
				value={value || ""}
				disabled={disabled}
				onChange={onChange}
				label={label}
				rows={rows}
				multiline={rows > 0}
				placeholder={placeholder || label}
				error={errored}
				helperText={helper}
				onFocus={(e) => {
					if (props?.type === "number")
						e.target.addEventListener(
							"wheel",
							function (e) {
								e.preventDefault();
							},
							{ passive: false }
						);
				}}
				{...props}
			/>
		);

	if (as === "masked") {
		return (
			<FormikField
				as={Masked}
				sx={sx}
				name={name}
				value={value || ""}
				disabled={disabled}
				onChange={setValue}
				label={label}
				placeholder={placeholder || label}
				errored={errored}
				helper={helper}
				{...props}
			/>
		);
	}

	if (as === "date") {
		return (
			<FormikField
				as={DatePicker}
				sx={sx}
				name={name}
				value={value}
				disabled={disabled}
				onChange={setValue}
				label={label}
				placeholder={placeholder}
				errored={errored}
				helper={helper}
				{...props}
			/>
		);
	}

	if (as === "buttons")
		return (
			<FormControl sx={sx}>
				{label && (
					<FormLabel id={name} error={errored}>
						{label}
					</FormLabel>
				)}
				{helper && <FormHelperText error={errored}>{helper}</FormHelperText>}
				<FormikField
					as={Buttons}
					name={name}
					value={value}
					disabled={disabled}
					onChange={setValue}
					{...props}
				/>
			</FormControl>
		);

	if (as === "radios")
		return (
			<FormControl sx={sx}>
				{label && (
					<FormLabel id={name} error={errored}>
						{label}
					</FormLabel>
				)}
				{helper && <FormHelperText error={errored}>{helper}</FormHelperText>}
				<FormikField
					as={Radios}
					name={name}
					value={value}
					disabled={disabled}
					onChange={setValue}
					{...props}
				/>
			</FormControl>
		);

	if (as === "check")
		return (
			<FormikField
				as={CheckBox}
				sx={sx}
				value={value}
				disabled={disabled}
				onChange={setValue}
				label={label}
				{...props}
			/>
		);

	if (as === "switch")
		return (
			<FormikField
				as={Switch}
				sx={sx}
				value={value}
				disabled={disabled}
				onChange={setValue}
				label={label}
				{...props}
			/>
		);

	if (as === "rich")
		return (
			<FormControl sx={{ minHeight: 300 }} error={errored}>
				{label && (
					<FormLabel id={name} error={errored}>
						{label}
					</FormLabel>
				)}
				{helper && <FormHelperText error={errored}>{helper}</FormHelperText>}
				<FormikField
					as={RichEditor}
					sx={sx}
					name={name}
					initialValue={initialValue}
					disabled={disabled}
					onChange={setValue}
					label={label}
					placeholder={placeholder}
					errored={errored}
					helper={helper}
					{...props}
				/>
			</FormControl>
		);

	if (as === "select") {
		return (
			<FormikField
				as={Select}
				sx={sx}
				name={name}
				value={value}
				disabled={disabled}
				onChange={setValue}
				label={label}
				placeholder={placeholder}
				errored={errored}
				helper={helper}
				{...props}
			/>
		);
	}

	if (as === "upload") {
		return (
			<FormControl sx={sx} error={errored}>
				{label && (
					<FormLabel id={name} error={errored}>
						{label}
					</FormLabel>
				)}
				{helper && <FormHelperText error={errored}>{helper}</FormHelperText>}
				<FormikField
					as={FileUpload}
					value={value}
					name={name}
					disabled={disabled}
					onChange={setValue}
					{...props}
				/>
			</FormControl>
		);
	}

	if (as === "point") {
		errored = !!error?.coordinates;
		helper = error?.coordinates;

		return (
			<FormControl sx={sx} error={errored}>
				{label && (
					<FormLabel id={name} error={errored}>
						{label}
					</FormLabel>
				)}
				{helper && <FormHelperText error={errored}>{helper}</FormHelperText>}
				{value && (
					<FormHelperText>{value?.coordinates.join(", ")}</FormHelperText>
				)}
				<FormikField
					as={PointSelect}
					value={value}
					name={name}
					disabled={disabled}
					onChange={setValue}
					defaultLng={initialValue?.coordinates[0]}
					defaultLat={initialValue?.coordinates[1]}
					{...props}
				/>
			</FormControl>
		);
	}

	return "Undefined control";
};

export default Field;
