import { useEffect, useState } from "react";
import { Autocomplete, Skeleton, TextField } from "@mui/material";
import { mainApi } from "../../providers/api";
import * as CONSTS from "../../consts";
import { useTranslation } from "react-i18next";

const Select = ({
	axio,
	axioAdapter,
	options = [],
	keyField = "_id",
	displayField = "name",
	path,
	lang = "mn",

	value,
	disabled,
	onChange,
	onChanged,
	multi,

	label,
	placeholder,
	errored,
	helper,
	sx,
}) => {
	const { t } = useTranslation();
	const [items, setItems] = useState(options);
	const [loading, setLoading] = useState(false);
	const [selected, setSelected] = useState(multi ? [] : null);

	const handleChange = (e, newValue) => {
		onChange(
			multi
				? newValue.map((option) => option[keyField])
				: newValue && newValue[keyField]
		);
	};

	useEffect(() => {
		let selected = multi
			? items.filter((item) => value?.includes(item[keyField]))
			: items.find((item) => value === (item && item[keyField])) || null;

		setSelected(selected);
		onChanged && onChanged(selected);
		// eslint-disable-next-line
	}, [items, value]);

	useEffect(() => {
		if (Array.isArray(CONSTS[path]))
			setItems(
				CONSTS[path].map((item) => ({
					_id: item,
					name: t(`const.${path}.${item}`, { lng: lang }),
				}))
			);
		// eslint-disable-next-line
	}, [path, lang]);

	useEffect(() => {
		const fetchItems = async () => {
			setLoading(true);

			const response = await mainApi(axio);
			const data =
				response && (axioAdapter ? axioAdapter(response.data) : response.data);

			setItems(data);
			setLoading(false);
		};

		if (axio) fetchItems();
		// eslint-disable-next-line
	}, [axio]);

	if (loading)
		return (
			<Skeleton variant="rectangular" animation="wave" sx={{ height: 56 }} />
		);

	return (
		<Autocomplete
			multiple={multi}
			value={selected}
			onChange={handleChange}
			disabled={disabled}
			options={items}
			noOptionsText="Хоосон"
			isOptionEqualToValue={(option, value) =>
				(option && option[keyField]) === (value && value[keyField])
			}
			getOptionLabel={(option) => option[displayField] ?? (multi ? [] : null)}
			renderInput={(params) => (
				<TextField
					{...params}
					sx={sx}
					error={errored}
					helperText={helper}
					placeholder={placeholder || label}
				/>
			)}
		/>
	);
};

export default Select;
