import { Box } from "@mui/material";
import { API_ROOT } from "./defines";
import objectPath from "object-path";

export const useField = (
	{ handleChange, values = {}, touched = {}, errors = {}, isSubmitting },
	field,
	{ fullWidth = true, label, disabled, ...rest }
) => {
	return {
		name: field,
		disabled: disabled || isSubmitting,
		value: values[field],
		onChange: handleChange,
		error: touched[field] && !!errors[field],
		helperText: touched[field] && errors[field],
		label,
		placeholder: label,
		fullWidth,
	};
};

export const firstChar = (str) => {
	return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const thousandSeparator = (amount, currency) => {
	if (!amount) return null;
	return (
		(+amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&'") +
		" " +
		(currency ? currency : "")
	);
};

export const imageField = (data, field, sx) => {
	let src = "/images/placeholder.jpg";

	const url = objectPath.get(data, field);

	if (url) {
		src = API_ROOT + "/" + url;
	}

	return (
		<Box sx={{ border: "1px solid #ddd", ...sx }} key={data?._id}>
			<img
				alt={url}
				style={{ width: "100%", height: "100%", objectFit: "contain" }}
				src={src}
				crossOrigin="anonymous"
			/>
		</Box>
	);
};

export const imageUrl = (field, index = 0) => {
	let src = "/images/placeholder.jpg";

	const url = objectPath.get(field, `${index}.id`);

	if (url) {
		src = API_ROOT + "/" + url;
	}

	return src;
};
