import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import PChart from "../../components/charts/Pie";

const Pies = () => {
	const { t } = useTranslation();
	return (
		<Box sx={{ display: { sm: "block", lg: "flex" } }}>
			<Box sx={{ flex: 1 }}>
				<Typography
					color="primary.main"
					fontWeight={"bold"}
					textAlign="center"
					fontSize={20}
				>
					{t("label.source")}
				</Typography>
				<PChart type="source" />
			</Box>
			<Box sx={{ flex: 1 }}>
				<Typography
					color="primary.main"
					fontWeight={"bold"}
					textAlign="center"
					fontSize={20}
				>
					{t("label.status")}
				</Typography>
				<PChart type="status" />
			</Box>
		</Box>
	);
};

export default Pies;
