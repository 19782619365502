import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import Field from "../../components/form/Field";
import Form from "../../components/form/Form";
import Group from "../../components/Group";

const CategoryForm = () => {
	const { id } = useParams();
	const form = {
		url: "category",
		id,
		readonly: false,
		initial: {
			type: "",
			name: "",
			trans: {
				us: {
					name: "",
				},
			},
		},
		validation: Yup.object({
			type: Yup.string().nullable().required("Төрөл сонгоно уу"),
			name: Yup.string().required("Ангилалын нэр оруулна уу"),
			trans: Yup.object({
				us: Yup.object({
					name: Yup.string().required("Ангилалын орчуулга оруулна уу"),
				}),
			}),
		}),
		sx: { width: { md: 1, lg: 1 / 3 } },
	};

	return (
		<Box>
			<Form {...form}>
				{({ disabled }) => {
					return (
						<Group>
							<Field
								disabled={disabled}
								name="type"
								label="Төрөл"
								as="buttons"
								path="category_type"
							/>
							<Field disabled={disabled} name="name" label="Ангилал" />
							<Field
								disabled={disabled}
								name="trans.us.name"
								label="Ангилал /English/"
							/>
						</Group>
					);
				}}
			</Form>
		</Box>
	);
};

export default CategoryForm;
