import { CancelOutlined, CheckCircle } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import List from "../../components/List";
import { useAuth } from "../../providers/auth";

const TaskList = ({ type }) => {
	const { user } = useAuth();

	return (
		<Box sx={{ p: 4 }}>
			<List
				add={type !== "user"}
				limit={50}
				find={type === "user" && { user: user.id }}
				sort={type === "user" ? { status: -1 } : { created: -1 }}
				url="/notification/table"
				more={({ project, _id }) => `/app/project/view/${project}?notif=${_id}`}
				moreText="Үргэлжлүүлэх"
				columns={[
					{
						field: "status",
						label: "Төлөв",
						render: ({ status, done }) => (
							<Typography
								textAlign={"center"}
								fontSize="small"
								color={
									status === "Мэдээлэл оруулсан" ? "success.main" : "error.main"
								}
							>
								{status === "Мэдээлэл оруулсан" ? (
									<CheckCircle />
								) : (
									<CancelOutlined />
								)}
								<br />
								{done
									? format(parseISO(done), "yyyy-MM-dd HH:mm:ss")
									: "Мэдээлэл оруулаагүй"}
							</Typography>
						),
					},
					{
						field: "project_name",
						label: "Төслийн нэр",
						width: 300,
						filter: true,
					},
					{
						field: "email",
						label: "Имэйл",
						filter: true,
					},
					{
						field: "user.firstname",
						label: "Нэр",
					},
					{
						field: "user.phone",
						label: "Утас",
					},
					{
						field: "info",
						label: "Тайлбар",
						width: 450,
					},
					{
						field: "created",
						label: "Мэдэгдэл илгээсэн огноо",
						width: 280,
						render: ({ created }) =>
							format(parseISO(created), "yyyy-MM-dd HH:mm:ss"),
					},
				]}
			/>
		</Box>
	);
};

export default TaskList;
