import { KeyboardArrowRight, Visibility } from "@mui/icons-material";
import {
	Box,
	Button,
	Card,
	CardContent,
	CardMedia,
	Grid,
	Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { imageField, imageUrl } from "../../utils";
import Progress from "../../components/Progress";

const ProjectCard = ({
	_id,
	progress,
	root,
	name,
	trans,
	thumbnail,
	_start,
	_end,
	funders,
	published,
	view_count,
}) => {
	const { t } = useTranslation();

	return (
		<Grid item md={12} lg={6} key={_id}>
			<Card sx={{ display: "flex", minHeight: 280 }}>
				<Box>
					<Typography
						variant="subtitle1"
						fontSize="small"
						sx={{
							lineHeight: 1.2,
							bgcolor: published ? "primary.main" : "error.main",
							p: 1,
							color: "#fff",
							borderBottom: "1px solid #fff",
						}}
					>
						{published ? "Нийтлэгдсэн" : "Драфт"}
					</Typography>
					<CardMedia
						component="img"
						sx={{
							width: 200,
							display: { xs: "none", sm: "block" },
							objectFit: "contain",
							objectPosition: "top",
						}}
						image={imageUrl(thumbnail)}
						alt={name}
						crossOrigin="anonymous"
					/>
				</Box>
				<CardContent
					sx={{
						flex: 1,
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
					}}
				>
					<Box>
						<Typography variant="subtitle1" sx={{ lineHeight: 1.2 }}>
							{name}
						</Typography>
						<Typography
							variant="subtitle1"
							color="text.secondary"
							fontSize="small"
							sx={{ lineHeight: 1, my: 2 }}
						>
							{trans?.us?.name}
						</Typography>
					</Box>
					<Box sx={{ display: "flex", flexDirection: "column" }}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								fontSize: 11,
							}}
						>
							<Box>{_start}</Box>
							<Box>{_end}</Box>
						</Box>
						<Progress progress={progress}></Progress>
						<Box
							sx={{
								mt: 2,
								display: "flex",
								flexWrap: "wrap",
								justifyContent: "space-between",
								alignItems: "center",
								gap: 1,
							}}
						>
							<Box sx={{ display: "flex", gap: 1 }}>
								{funders.map((funder) => {
									return imageField(funder, "logo.0.id", { height: 30 });
								})}
							</Box>
							<Button
								component={Link}
								to={root + _id}
								variant="outlined"
								endIcon={<KeyboardArrowRight />}
							>
								{t("button.more")}
							</Button>
						</Box>

						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "end",
								color: "gray",
								mt: 1,
							}}
							fontSize="small"
						>
							<Visibility sx={{ mr: 1 }} />
							{view_count}
						</Box>
					</Box>
				</CardContent>
			</Card>
		</Grid>
	);
};

export default ProjectCard;
