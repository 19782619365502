import { useState } from "react";
import {
	Box,
	IconButton,
	Menu,
	MenuItem,
	Tooltip,
	Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../../defines";
import { useEffect } from "react";
import ReactCountryFlag from "react-country-flag";

const FLAGS = { mon: "mn", eng: "gb" };

const LanguageSwitch = () => {
	const [open, setOpen] = useState(null);

	const { t, i18n } = useTranslation();

	const { language, changeLanguage } = i18n;

	const handleOpen = (event) => {
		setOpen(event.currentTarget);
	};

	const handleClose = () => {
		setOpen(null);
	};

	useEffect(() => {
		if (!LANGUAGES.includes(language)) {
			changeLanguage(LANGUAGES[0]);
		}
	}, [language, changeLanguage]);

	return (
		<Box sx={{ flexGrow: 0 }}>
			<Tooltip title={t("button.switch_language")}>
				<IconButton onClick={handleOpen} sx={{ p: 0 }}>
					<ReactCountryFlag svg={true} countryCode={FLAGS[language]} />
					<Typography
						textAlign="center"
						textTransform={"uppercase"}
						color="#fff"
						sx={{ ml: 1 }}
					>
						{language}
					</Typography>
				</IconButton>
			</Tooltip>
			<Menu
				sx={{ mt: "45px" }}
				id="menu-appbar"
				anchorEl={open}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={Boolean(open)}
				onClose={handleClose}
			>
				{LANGUAGES.map((locale) => (
					<MenuItem
						key={locale}
						onClick={() => {
							changeLanguage(locale);
							handleClose();
						}}
					>
						<ReactCountryFlag svg={true} countryCode={FLAGS[locale]} />
						<Typography
							sx={{ ml: 1, textTransform: "uppercase", textAlign: "center" }}
						>
							{locale}
						</Typography>
					</MenuItem>
				))}
			</Menu>
		</Box>
	);
};

export default LanguageSwitch;
