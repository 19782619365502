import { grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export default createTheme({
	palette: {
		primary: {
			light: "#5592c9",
			main: "#2a57a4",
			dark: "#193674",
			contrastText: "#fff",
		},
		secondary: {
			light: "#fca312",
			main: "#fca312",
			dark: "#fca312",
			contrastText: "#fff",
		},

		grey: {
			light: grey[300],
			main: grey[400],
			dark: grey[700],
			contrastText: "#fff",
		},
	},
	overrides: {
		MUIRichTextEditor: {
			editor: {
				minHeight: 32,
				borderBottom: `1px solid ${grey[300]}`,
			},
		},
	},
});
