import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker as MuiDateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";

const DatePicker = ({
	value = null,
	disabled,
	onChange,
	label,
	placeholder,
	errored,
	helper,
	format,
	sx,
	type = "date",
}) => {
	const inputFormat =
		format ?? type === "date" ? "yyyy-MM-dd" : "yyyy-MM-dd HH:mm:ss";

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			{type === "date" ? (
				<MuiDatePicker
					label={label}
					value={value}
					disabled={disabled}
					onChange={onChange}
					inputFormat={inputFormat}
					renderInput={(params) => (
						<TextField
							{...params}
							sx={sx}
							error={errored}
							helperText={helper}
							placeholder={placeholder || inputFormat || label}
						/>
					)}
				/>
			) : (
				<MuiDateTimePicker
					label={label}
					value={value}
					disabled={disabled}
					onChange={onChange}
					inputFormat={inputFormat}
					renderInput={(params) => (
						<TextField
							{...params}
							sx={sx}
							error={errored}
							helperText={helper}
							placeholder={placeholder || inputFormat || label}
						/>
					)}
				/>
			)}
		</LocalizationProvider>
	);
};

export default DatePicker;
