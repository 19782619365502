import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { GetFromStorage, SaveToStorage, RemoveFromStorage } from "./storage";
import { API_ROOT, VERSION } from "../defines";

const mainApi = axios.create({
	baseURL: API_ROOT,
});

mainApi.interceptors.request.use((request) => {
	const accessToken = GetFromStorage("accessToken");
	const language = GetFromStorage("i18next");

	if (accessToken) request.headers["Authorization"] = `Bearer ${accessToken}`;
	if (language) request.headers["Accept-Language"] = language;

	request.headers["Accept-Version"] = `ADMIN@${VERSION}`;

	return request;
});

mainApi.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error?.response?.status === 401) {
			return Promise.reject(error);
		}
		if (error?.response?.status === 403) {
			RemoveFromStorage("refreshToken");
			RemoveFromStorage("accessToken");

			window.location = "/page/logout";
		}
		if (error?.response?.status === 400) {
			if (typeof error?.response?.data === "string")
				return Promise.reject(error.response.data);

			return Promise.reject(
				error?.response?.data?.message,
				error?.response?.data?.payload
			);
		}
		if (error?.response?.status === 404) {
			return Promise.reject("Мэдээлэл олдсонгүй");
		}
		if (error.message === "Network Error") {
			return Promise.reject("Сүлжээний алдаа");
		}

		return Promise.reject(
			error?.response?.data?.message ?? error.message ?? error.toString()
		);
	}
);

createAuthRefreshInterceptor(mainApi, (failedRequest) =>
	mainApi({
		method: "POST",
		url: "/user/refresh",
		data: { token: GetFromStorage("refreshToken") },
	})
		.then(({ data }) => {
			SaveToStorage("refreshToken", data.refreshToken);
			SaveToStorage("accessToken", data.accessToken);

			failedRequest.response.config.headers["Authorization"] =
				"Bearer " + data.accessToken;

			return Promise.resolve();
		})
		.catch((error) => {
			return Promise.reject(error);
		})
);

export { mainApi };
