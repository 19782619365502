import Mongolia from "../map/Mongolia";
import { useEffect, useState } from "react";
import { mainApi } from "../../providers/api";
import {
	Backdrop,
	Box,
	CircularProgress,
	Grid,
	Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { GetFromStorage, SaveToStorage } from "../../providers/storage";

const Target = () => {
	const { t, i18n } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();
	const [selected, setSelected] = useState(GetFromStorage("location"));
	const [projects, setProjects] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const response = await mainApi("/public/target");
			setData(response.data);
			!selected && setSelected("all");
			setLoading(false);
		};

		fetchData();
	}, []);

	useEffect(() => {
		let item = data && data.find((f) => f._id == selected);
		setProjects(item?.projects || []);
		SaveToStorage("location", selected);
	}, [selected, data]);

	return (
		<Box>
			<Backdrop sx={{ color: "#fff", zIndex: 11 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Box sx={{ display: { sm: "block", md: "flex" } }} fontSize="small">
				<Box sx={{ width: 180, flexGrow: 0 }}>
					{data &&
						data.map(({ _id, total }) => (
							<Grid
								key={_id}
								item
								xs={6}
								sm={4}
								md={3}
								sx={{ cursor: "pointer" }}
								onClick={() => {
									setSelected(_id);
								}}
							>
								<Box
									sx={
										_id == selected
											? { fontWeight: "bold", textTransform: "underline" }
											: {}
									}
								>
									{t(`const.location.${_id}`)}: {total}
								</Box>
							</Grid>
						))}
				</Box>
				<Box
					sx={{
						flex: 1,
						px: { sm: 0, md: 2 },
						borderLeft: { sm: "none", md: "1px solid #f7f7f7" },
					}}
				>
					{projects.map(({ _id, name }, index) => {
						return (
							<Box key={_id} fontSize={"small"}>
								<Typography
									component={Link}
									sx={{ color: "primary.main", fontSize: 12 }}
									to={`/page/project/${_id}`}
								>{`${index + 1}. ${name[i18n.language]}`}</Typography>
							</Box>
						);
					})}
					<Mongolia
						label={(zipcode) => {
							let item = data && data.find((f) => f._id == zipcode);
							return item && item.total;
						}}
						selected={selected}
						setSelected={setSelected}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default Target;
