import { useContext, createContext, useState } from "react";
import { Alert, Box, Modal, Snackbar, Fade } from "@mui/material";

const ModalContext = createContext({
	open: () => {},
	close: () => {},
	toast: () => {},
});

const ModalProvider = ({ children }) => {
	const [opened, setOpened] = useState(false);
	const [elements, setElements] = useState();
	const [toast, setToast] = useState();

	const handleOpen = (elements) => {
		setElements(elements);
		setOpened(true);
	};

	const handleClose = () => {
		setOpened(false);
	};

	const showToast = (message, type, props) => {
		setToast({ ...props, message, severity: type });
	};

	return (
		<ModalContext.Provider
			value={{ open: handleOpen, close: handleClose, toast: showToast }}
		>
			<Modal open={opened} onClose={handleClose}>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: 400,
						bgcolor: "background.paper",
						boxShadow: 24,
						p: 4,
					}}
				>
					{elements}
				</Box>
			</Modal>
			{toast && (
				<Snackbar
					open={!!toast}
					sx={{ boxShadow: 5 }}
					autoHideDuration={toast?.duration ?? 5000}
					onClose={() => {
						setToast();
					}}
					anchorOrigin={{
						vertical: toast?.vertical ?? "top",
						horizontal: toast?.horizontal ?? "center",
					}}
					TransitionComponent={(props) => <Fade {...props} direction="down" />}
				>
					{toast && (
						<Alert severity={toast?.severity} sx={{ width: "100%" }}>
							{toast?.message}
						</Alert>
					)}
				</Snackbar>
			)}
			{children}
		</ModalContext.Provider>
	);
};

export const useModal = () => useContext(ModalContext);

export default ModalProvider;
