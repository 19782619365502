import { Backdrop, Box, CircularProgress, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { mainApi } from "../providers/api";
import View from "../models/project/View";

const ProjecDetail = () => {
	const { id } = useParams();
	const [data, setData] = useState();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetchProject = async () => {
			setLoading(true);
			const response = await mainApi(`/public/project/${id}`);
			setData(response.data);
			setLoading(false);
		};

		fetchProject();
	}, [id]);

	return (
		<Box>
			<Backdrop sx={{ color: "#fff", zIndex: 11 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Container sx={{ my: 5 }}>
				{data && <View {...data} pub={true} />}
			</Container>
		</Box>
	);
};

export default ProjecDetail;
