export const category_type = ["sector", "segment", "currency"];
export const org_type = ["funder", "implementer"];
export const role = ["superior", "admin"];
export const source = ["aid", "loan"];
export const status = ["pending", "inprogress", "completed"];
export const location = [
	"all",
	"11000",
	"12000",
	"12300",
	"16000",
	"13000",
	"12600",
	"18000",
	"14000",
	"17000",
	"15000",
	"65000",
	"83000",
	"64000",
	"63000",
	"82000",
	"42000",
	"45000",
	"44000",
	"21000",
	"48000",
	"81000",
	"61000",
	"62000",
	"46000",
	"22000",
	"43000",
	"41000",
	"85000",
	"84000",
	"67000",
	"23000",
];
export const acc3 = ["yes", "no"];
export const acc5 = ["yes", "no", "required"];
