import { Box, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import Field from "../../components/form/Field";
import Form from "../../components/form/Form";
import Group from "../../components/Group";
import { useAuth } from "../../providers/auth";

const UserForm = ({ profile }) => {
	const { id } = useParams();
	const { user } = useAuth();

	const form = {
		url: "user",
		id: profile ? user.id : id,
		readonly: false,
		initial: {
			role: "",
			email: "",
			firstname: "",
			lastname: "",
			phone: "",
			position: "",
			active: true,
		},
		validation: Yup.object({
			role: Yup.string().required("Хандах эрх сонгоно уу"),
			email: Yup.string().required("Имэйл оруулна уу"),
			firstname: Yup.string().required("Нэр оруулна уу"),
			phone: Yup.string().required("Утас оруулна уу"),
		}),
		sx: profile ? {} : { width: { md: 1, lg: 1 / 2 } },
	};

	const resetPassword = async ({ values, setFieldValue, submitForm }) => {
		setFieldValue("new_password", values.phone);
		setFieldValue("re_password", values.phone);
		submitForm();
	};

	return (
		<Form {...form}>
			{({ disabled, ...props }) => {
				return (
					<Box sx={{ display: { xs: "grid", lg: "flex" }, gap: 2, mb: 2 }}>
						<Group>
							<Field
								disabled={profile || disabled}
								name="role"
								label="Хандах эрх"
								as="buttons"
								path="role"
							/>
							{user.role === "superior" && (
								<Box>
									<Box color="grey" fontSize="small" sx={{ mb: 1 }}>
										Нууц үг сэргээх товчыг дарсанаар тухайн хэрэглэгчийн анхны
										нууц үг болох утасны дугаараар нууц үүсгэгдэнэ.
									</Box>
									<Button
										disabled={disabled}
										variant="contained"
										onClick={() => {
											resetPassword(props);
										}}
									>
										Нууц үг сэргээх
									</Button>
								</Box>
							)}
							<Field
								disabled={profile || disabled}
								name="active"
								label="Идэвхтэй эсэх"
								as="switch"
							/>
							<Field
								disabled={profile || disabled}
								name="email"
								label="Имэйл"
							/>
							<Field disabled={profile || disabled} name="phone" label="Утас" />
							<Field
								disabled={profile || disabled}
								name="lastname"
								label="Овог"
							/>
							<Field
								disabled={profile || disabled}
								name="firstname"
								label="Нэр"
							/>
							<Field
								disabled={profile || disabled}
								name="position"
								label="Албан тушаал"
								rows={5}
							/>
						</Group>
						{profile && (
							<Group title={"Нууц үг солих"}>
								<Field
									disabled={disabled}
									type="password"
									name="new_password"
									label="Шинэ нууц үг"
								/>
								<Field
									disabled={disabled}
									type="password"
									name="re_password"
									label="Нууц үг баталгаажуулах"
								/>
							</Group>
						)}
					</Box>
				);
			}}
		</Form>
	);
};

export default UserForm;
