import {
	Box,
	Container,
	Divider,
	Grid,
	IconButton,
	InputBase,
	Paper,
	Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Search } from "@mui/icons-material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { mainApi } from "../providers/api";
import LatestProjects from "../models/project/Latest";
import Target from "../components/charts/Target";
import TMChart from "../components/charts/TreeMap";
import Pies from "../models/project/Pies";
import GanttChart from "../components/charts/Gantt";

const Home = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [search, setSearch] = useState("");
	const [counts, setCounts] = useState();

	const fetchCounts = async () => {
		const counts = await mainApi("/public/count");
		setCounts(counts.data);
	};

	useEffect(() => {
		fetchCounts();
		// window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);

	return (
		<Box>
			<Grid container sx={{ maxWidth: 1500, mx: "auto" }}>
				<Grid item xs={12} md={6} sx={{ display: "flex" }}>
					<img
						alt={"projects"}
						src="/images/banner.png"
						style={{
							width: "100%",
							objectFit: "contain",
							objectPosition: "center",
						}}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Box
						sx={{
							padding: 3,
							flex: 1,
							my: { xs: 0, md: 8 },
							justifyContent: "center",
							alignItems: { xs: "center", md: "start" },
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Typography
							sx={{
								color: "primary.main",
								textTransform: "uppercase",
								maxWidth: 550,
								textAlign: { xs: "center", md: "left" },
							}}
							fontSize="medium"
							fontWeight="bold"
						>
							{t("common.system_name")}
						</Typography>
						<Paper
							component="form"
							onSubmit={(e) => {
								e.preventDefault();
								navigate(`/page/project?name=${search}`);
							}}
							sx={{
								p: "2px 4px",
								display: "flex",
								alignItems: "center",
								width: 300,
								my: 4,
							}}
						>
							<InputBase
								sx={{ ml: 1, flex: 1 }}
								placeholder={t("label.search")}
								value={search}
								onChange={({ target }) => {
									setSearch(target.value);
								}}
							/>
							<IconButton type="submit" sx={{ p: "10px" }}>
								<Search />
							</IconButton>
						</Paper>
						{counts && (
							<Box sx={{ display: "flex", gap: 6, color: "primary.main" }}>
								<Box>
									<Typography
										sx={{ fontWeight: "bold", fontSize: 45, lineHeight: 1 }}
									>
										{counts.orgs}
									</Typography>
									<Typography>{t("model.orgs")}</Typography>
								</Box>
								<Box>
									<Typography
										sx={{ fontWeight: "bold", fontSize: 45, lineHeight: 1 }}
									>
										{counts.projects}
									</Typography>
									<Typography>{t("model.projects")}</Typography>
								</Box>
							</Box>
						)}
					</Box>
				</Grid>
			</Grid>
			<Divider></Divider>
			<Box sx={{ bgcolor: "#f7f7f7", py: 5, pt: 2 }}>
				<Container>
					<LatestProjects></LatestProjects>
				</Container>
			</Box>
			<Container>
				<Box sx={{ mb: 4 }}>
					<Typography
						color="primary.main"
						fontWeight={"bold"}
						textAlign="center"
						fontSize={20}
						my={8}
					>
						{t("label.project_locations")}
					</Typography>
					<Target />
				</Box>
			</Container>
			<Box sx={{ bgcolor: "#f7f7f7", py: 5, pt: 2 }}>
				<Container>
					<Box>
						<Typography
							color="primary.main"
							fontWeight={"bold"}
							textAlign="center"
							fontSize={20}
							my={8}
						>
							{t("label.project_funders")}
						</Typography>
						<TMChart></TMChart>
					</Box>
				</Container>
			</Box>
			<Container>
				<Box sx={{ mb: 5 }}>
					<Typography
						color="primary.main"
						fontWeight={"bold"}
						textAlign="center"
						fontSize={20}
						my={8}
					>
						{t("label.project_implementers")}
					</Typography>
					<TMChart type="implementers" color="#2a57a4"></TMChart>
				</Box>
			</Container>
			<Divider />
			<Container sx={{ my: 8 }}>
				<Pies />
			</Container>
			<Divider />
			<Container sx={{ my: 8 }}>
				<GanttChart />
			</Container>
		</Box>
	);
};

export default Home;
